/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SemesterEnum,
    SemesterEnumFromJSON,
    SemesterEnumFromJSONTyped,
    SemesterEnumToJSON,
} from './SemesterEnum';
import {
    SpecializationEnum,
    SpecializationEnumFromJSON,
    SpecializationEnumFromJSONTyped,
    SpecializationEnumToJSON,
} from './SpecializationEnum';

/**
 * 
 * @export
 * @interface ConfigureOptionalsDTO
 */
export interface ConfigureOptionalsDTO {
    /**
     * 
     * @type {string}
     * @memberof ConfigureOptionalsDTO
     */
    id?: string;
    /**
     * 
     * @type {SpecializationEnum}
     * @memberof ConfigureOptionalsDTO
     */
    specialization?: SpecializationEnum;
    /**
     * 
     * @type {SemesterEnum}
     * @memberof ConfigureOptionalsDTO
     */
    semester?: SemesterEnum;
}

export function ConfigureOptionalsDTOFromJSON(json: any): ConfigureOptionalsDTO {
    return ConfigureOptionalsDTOFromJSONTyped(json, false);
}

export function ConfigureOptionalsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigureOptionalsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'specialization': !exists(json, 'specialization') ? undefined : SpecializationEnumFromJSON(json['specialization']),
        'semester': !exists(json, 'semester') ? undefined : SemesterEnumFromJSON(json['semester']),
    };
}

export function ConfigureOptionalsDTOToJSON(value?: ConfigureOptionalsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'specialization': SpecializationEnumToJSON(value.specialization),
        'semester': SemesterEnumToJSON(value.semester),
    };
}

