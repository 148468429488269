/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubjectTypeEnum,
    SubjectTypeEnumFromJSON,
    SubjectTypeEnumFromJSONTyped,
    SubjectTypeEnumToJSON,
} from './SubjectTypeEnum';

/**
 * 
 * @export
 * @interface SimpleSubjectPlanDTO
 */
export interface SimpleSubjectPlanDTO {
    /**
     * 
     * @type {string}
     * @memberof SimpleSubjectPlanDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleSubjectPlanDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleSubjectPlanDTO
     */
    series?: string | null;
    /**
     * 
     * @type {SubjectTypeEnum}
     * @memberof SimpleSubjectPlanDTO
     */
    type?: SubjectTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SimpleSubjectPlanDTO
     */
    credits?: number;
}

export function SimpleSubjectPlanDTOFromJSON(json: any): SimpleSubjectPlanDTO {
    return SimpleSubjectPlanDTOFromJSONTyped(json, false);
}

export function SimpleSubjectPlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleSubjectPlanDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'type': !exists(json, 'type') ? undefined : SubjectTypeEnumFromJSON(json['type']),
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
    };
}

export function SimpleSubjectPlanDTOToJSON(value?: SimpleSubjectPlanDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'series': value.series,
        'type': SubjectTypeEnumToJSON(value.type),
        'credits': value.credits,
    };
}

