/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShortSemesterEnum,
    ShortSemesterEnumFromJSON,
    ShortSemesterEnumFromJSONTyped,
    ShortSemesterEnumToJSON,
} from './ShortSemesterEnum';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';
import {
    SubjectTypeEnum,
    SubjectTypeEnumFromJSON,
    SubjectTypeEnumFromJSONTyped,
    SubjectTypeEnumToJSON,
} from './SubjectTypeEnum';

/**
 * 
 * @export
 * @interface SubjectPlanDTO
 */
export interface SubjectPlanDTO {
    /**
     * 
     * @type {string}
     * @memberof SubjectPlanDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectPlanDTO
     */
    name?: string | null;
    /**
     * 
     * @type {SubjectTypeEnum}
     * @memberof SubjectPlanDTO
     */
    type?: SubjectTypeEnum;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof SubjectPlanDTO
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {ShortSemesterEnum}
     * @memberof SubjectPlanDTO
     */
    semester?: ShortSemesterEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectPlanDTO
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectPlanDTO
     */
    series?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlanDTO
     */
    initialAvailableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlanDTO
     */
    availableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlanDTO
     */
    credits?: number;
}

export function SubjectPlanDTOFromJSON(json: any): SubjectPlanDTO {
    return SubjectPlanDTOFromJSONTyped(json, false);
}

export function SubjectPlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectPlanDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : SubjectTypeEnumFromJSON(json['type']),
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'semester': !exists(json, 'semester') ? undefined : ShortSemesterEnumFromJSON(json['semester']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'initialAvailableSpots': !exists(json, 'initialAvailableSpots') ? undefined : json['initialAvailableSpots'],
        'availableSpots': !exists(json, 'availableSpots') ? undefined : json['availableSpots'],
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
    };
}

export function SubjectPlanDTOToJSON(value?: SubjectPlanDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': SubjectTypeEnumToJSON(value.type),
        'year': StudentYearEnumToJSON(value.year),
        'semester': ShortSemesterEnumToJSON(value.semester),
        'domain': value.domain,
        'series': value.series,
        'initialAvailableSpots': value.initialAvailableSpots,
        'availableSpots': value.availableSpots,
        'credits': value.credits,
    };
}

