/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeRequestStatusDTO,
    ChangeRequestStatusDTOFromJSON,
    ChangeRequestStatusDTOFromJSONTyped,
    ChangeRequestStatusDTOToJSON,
} from './ChangeRequestStatusDTO';

/**
 * 
 * @export
 * @interface ChangeRequestResponseDTO
 */
export interface ChangeRequestResponseDTO {
    /**
     * 
     * @type {Array<ChangeRequestStatusDTO>}
     * @memberof ChangeRequestResponseDTO
     */
    requests?: Array<ChangeRequestStatusDTO> | null;
}

export function ChangeRequestResponseDTOFromJSON(json: any): ChangeRequestResponseDTO {
    return ChangeRequestResponseDTOFromJSONTyped(json, false);
}

export function ChangeRequestResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requests': !exists(json, 'requests') ? undefined : (json['requests'] === null ? null : (json['requests'] as Array<any>).map(ChangeRequestStatusDTOFromJSON)),
    };
}

export function ChangeRequestResponseDTOToJSON(value?: ChangeRequestResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requests': value.requests === undefined ? undefined : (value.requests === null ? null : (value.requests as Array<any>).map(ChangeRequestStatusDTOToJSON)),
    };
}

