/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserChoicesDTO
 */
export interface UserChoicesDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserChoicesDTO
     */
    choices?: Array<string> | null;
}

export function UserChoicesDTOFromJSON(json: any): UserChoicesDTO {
    return UserChoicesDTOFromJSONTyped(json, false);
}

export function UserChoicesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserChoicesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'choices': !exists(json, 'choices') ? undefined : json['choices'],
    };
}

export function UserChoicesDTOToJSON(value?: UserChoicesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'choices': value.choices,
    };
}

