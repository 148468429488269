/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApproveChangeRequestsSimulationDTO,
    ApproveChangeRequestsSimulationDTOFromJSON,
    ApproveChangeRequestsSimulationDTOToJSON,
    ApproveChangeRequestsSimulationResponseDTO,
    ApproveChangeRequestsSimulationResponseDTOFromJSON,
    ApproveChangeRequestsSimulationResponseDTOToJSON,
} from '../models';

export interface ApiApproveChangeRequestAcceptChangeRequestsPostRequest {
    approveChangeRequestsSimulationDTO?: ApproveChangeRequestsSimulationDTO;
}

export interface ApiApproveChangeRequestSimulatePostRequest {
    approveChangeRequestsSimulationDTO?: ApproveChangeRequestsSimulationDTO;
}

/**
 * 
 */
export class ApproveChangeRequestApi extends runtime.BaseAPI {

    /**
     */
    async apiApproveChangeRequestAcceptChangeRequestsPostRaw(requestParameters: ApiApproveChangeRequestAcceptChangeRequestsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApproveChangeRequestsSimulationResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ApproveChangeRequest/AcceptChangeRequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveChangeRequestsSimulationDTOToJSON(requestParameters.approveChangeRequestsSimulationDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApproveChangeRequestsSimulationResponseDTOFromJSON));
    }

    /**
     */
    async apiApproveChangeRequestAcceptChangeRequestsPost(requestParameters: ApiApproveChangeRequestAcceptChangeRequestsPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ApproveChangeRequestsSimulationResponseDTO>> {
        const response = await this.apiApproveChangeRequestAcceptChangeRequestsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiApproveChangeRequestSimulatePostRaw(requestParameters: ApiApproveChangeRequestSimulatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApproveChangeRequestsSimulationResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ApproveChangeRequest/Simulate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApproveChangeRequestsSimulationDTOToJSON(requestParameters.approveChangeRequestsSimulationDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApproveChangeRequestsSimulationResponseDTOFromJSON));
    }

    /**
     */
    async apiApproveChangeRequestSimulatePost(requestParameters: ApiApproveChangeRequestSimulatePostRequest = {}, initOverrides?: RequestInit): Promise<Array<ApproveChangeRequestsSimulationResponseDTO>> {
        const response = await this.apiApproveChangeRequestSimulatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
