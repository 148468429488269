/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SelectionCountResponseDTO,
    SelectionCountResponseDTOFromJSON,
    SelectionCountResponseDTOToJSON,
    SemesterEnum,
    SemesterEnumFromJSON,
    SemesterEnumToJSON,
    SemesterRepartitionDTO,
    SemesterRepartitionDTOFromJSON,
    SemesterRepartitionDTOToJSON,
    SimulationRequestDTO,
    SimulationRequestDTOFromJSON,
    SimulationRequestDTOToJSON,
    SimulationResponseDTO,
    SimulationResponseDTOFromJSON,
    SimulationResponseDTOToJSON,
    SpecializationRepartitionDTO,
    SpecializationRepartitionDTOFromJSON,
    SpecializationRepartitionDTOToJSON,
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumToJSON,
} from '../models';

export interface ApiRepartitionDistributePostRequest {
    simulationRequestDTO?: SimulationRequestDTO;
}

export interface ApiRepartitionGetSelectionCountGetRequest {
    domain?: string;
    year?: StudentYearEnum;
}

export interface ApiRepartitionGetSemesterRepartitionGetRequest {
    semester?: SemesterEnum;
}

export interface ApiRepartitionSimulatePostRequest {
    simulationRequestDTO?: SimulationRequestDTO;
}

/**
 * 
 */
export class RepartitionApi extends runtime.BaseAPI {

    /**
     */
    async apiRepartitionDistributePostRaw(requestParameters: ApiRepartitionDistributePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SimulationResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/Distribute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SimulationRequestDTOToJSON(requestParameters.simulationRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimulationResponseDTOFromJSON));
    }

    /**
     */
    async apiRepartitionDistributePost(requestParameters: ApiRepartitionDistributePostRequest = {}, initOverrides?: RequestInit): Promise<Array<SimulationResponseDTO>> {
        const response = await this.apiRepartitionDistributePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRepartitionExportRepartitionResultsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/ExportRepartitionResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRepartitionExportRepartitionResultsPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiRepartitionExportRepartitionResultsPostRaw(initOverrides);
    }

    /**
     */
    async apiRepartitionGetSelectionCountGetRaw(requestParameters: ApiRepartitionGetSelectionCountGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SelectionCountResponseDTO>> {
        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['Year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/GetSelectionCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SelectionCountResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiRepartitionGetSelectionCountGet(requestParameters: ApiRepartitionGetSelectionCountGetRequest = {}, initOverrides?: RequestInit): Promise<SelectionCountResponseDTO> {
        const response = await this.apiRepartitionGetSelectionCountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRepartitionGetSemesterRepartitionGetRaw(requestParameters: ApiRepartitionGetSemesterRepartitionGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SemesterRepartitionDTO>> {
        const queryParameters: any = {};

        if (requestParameters.semester !== undefined) {
            queryParameters['semester'] = requestParameters.semester;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/GetSemesterRepartition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SemesterRepartitionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiRepartitionGetSemesterRepartitionGet(requestParameters: ApiRepartitionGetSemesterRepartitionGetRequest = {}, initOverrides?: RequestInit): Promise<SemesterRepartitionDTO> {
        const response = await this.apiRepartitionGetSemesterRepartitionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRepartitionGetSpecializationRepartitionGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<SpecializationRepartitionDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/GetSpecializationRepartition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecializationRepartitionDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiRepartitionGetSpecializationRepartitionGet(initOverrides?: RequestInit): Promise<SpecializationRepartitionDTO> {
        const response = await this.apiRepartitionGetSpecializationRepartitionGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRepartitionGetStudentGradeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/GetStudentGrade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiRepartitionGetStudentGradeGet(initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiRepartitionGetStudentGradeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRepartitionSimulatePostRaw(requestParameters: ApiRepartitionSimulatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SimulationResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Repartition/Simulate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SimulationRequestDTOToJSON(requestParameters.simulationRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimulationResponseDTOFromJSON));
    }

    /**
     */
    async apiRepartitionSimulatePost(requestParameters: ApiRepartitionSimulatePostRequest = {}, initOverrides?: RequestInit): Promise<Array<SimulationResponseDTO>> {
        const response = await this.apiRepartitionSimulatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
