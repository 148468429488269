import { ApproveChangeRequestApi } from "../Api/apis";
import { StudentYearEnum } from "../Api/models";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new ApproveChangeRequestApi(getAuthConfiguration());

export const simulateChangeRequests = (year: StudentYearEnum, domain: string) => {
    return ApiFactory().apiApproveChangeRequestSimulatePost({ approveChangeRequestsSimulationDTO: { year, domain } });
}

export const distributeChangeRequests = (year: StudentYearEnum, domain: string) => {
    return ApiFactory().apiApproveChangeRequestAcceptChangeRequestsPost({ approveChangeRequestsSimulationDTO: { year, domain } });
}

