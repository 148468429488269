import { RepartitionApi } from "../Api/apis";
import { SemesterEnum, SimulationType, StudentYearEnum } from "../Api/models";
import { getAuthConfiguration } from "./configuration";

const AuthRepartitionApiFactory = () => new RepartitionApi(getAuthConfiguration());

export const getSpecializationRepartition = () => {
    return AuthRepartitionApiFactory().apiRepartitionGetSpecializationRepartitionGet();
}

export const getOptionalsRepartition = (semester?: SemesterEnum) => {
    return AuthRepartitionApiFactory().apiRepartitionGetSemesterRepartitionGet({semester});
}

export const getStudentGrade = () => {
    return AuthRepartitionApiFactory().apiRepartitionGetStudentGradeGet();
}

export const simulate = (domain: string, semester: SemesterEnum, type: SimulationType, year: StudentYearEnum) => {
    return AuthRepartitionApiFactory().apiRepartitionSimulatePost({simulationRequestDTO: {domain, semester, type, year}});
}

export const distribute = (domain: string, semester: SemesterEnum, type: SimulationType, year: StudentYearEnum) => {
    return AuthRepartitionApiFactory().apiRepartitionDistributePost({simulationRequestDTO: {domain, semester, type, year}});
}

export const exportRepartitionResults = () => {
    return AuthRepartitionApiFactory().apiRepartitionExportRepartitionResultsPost();
}