import { Form, Input, InputNumber, Popconfirm, Select, Table, Typography } from "antd";
import Search from 'antd/lib/input/Search';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { SemesterEnum, ShortSemesterEnum, StudentYearEnum, Subject } from "../../Api";
import Layout from "../../Containers/Layout";
import { GetAllMasterSubjectsFiltered, UpdateSpotsMaster } from "../../Requests/subject-requests";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Subject;
    index: number;
    children: React.ReactNode;
  }
  
  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber onFocus={(event) => {event.target.select();}} /> : <Input onFocus={(event) => {event.target.select();}} />;
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

const AdminMaster = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTermProgramName, setSearchTermProgramName] = useState('');
    const [searchTermDomain, setSearchTermDomain] = useState('');
    const [searchTermYear, setSearchTermYear] = useState(StudentYearEnum.NotApplicable);
    const { Option } = Select;

    const queryClient = useQueryClient();

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record: Subject) => record.id === editingKey;

    const invalidateQuery = () => { queryClient.invalidateQueries('GetAllMasterSubjectsFiltered') };

    const openSpecializationErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchSpecializationsError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const openErrorNotification = (_error: any) => {
      openNotification(
          t('optionals.error'),
          'Datele nu au putut fi actualizate cu succes!',
          NOTIFICATION_TYPES.ERROR
        );
    }

    const openForbiddenErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Nu ai permisiuni pentru a efectua operatia!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const edit = (record: Partial<Subject> & { id?: string }) => {
        form.setFieldsValue({ minimumSpots: '', maximumSpots: '', enrolledStudents: '', ...record });
        setEditingKey(record.id!);
      };
    
      const cancel = () => {
        setEditingKey('');
      };
    
      const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as Subject;
    
            const newData = [...data ?? []];
            const index = newData.findIndex((item) => key === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                ...item,
                ...row,
                });
                console.log(newData[index]);
                await UpdateSpotsMaster({updateNumberOfSpotsMasterDTO: {enrolledStudents: newData[index].initialEnrolledStudents, 
                                                                        maximumSpots: newData[index].maximumSpots,
                                                                        minimumSpots: newData[index].minimumSpots,
                                                                        id: newData[index].id}})
                .then(() => {
                    invalidateQuery();
                });
                // setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                // setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
          const errorWithResponse = errInfo as {status?: number};
          if (errorWithResponse.status) {
              const statusCode = errorWithResponse.status;
              if (statusCode === 403) {
                  openForbiddenErrorNotification(errInfo);
              } else {
                  openErrorNotification(errInfo); 
              }
          } else {
            openErrorNotification(errInfo); 
          }
        }
      };


    const { data, isLoading } = useQuery(['GetAllMasterSubjectsFiltered', searchTermName, searchTermDomain, searchTermYear, searchTermProgramName],
        () => {
            return GetAllMasterSubjectsFiltered(searchTermName, searchTermDomain, SemesterEnum.All, searchTermYear, searchTermProgramName);
        },
        {
            onError: openSpecializationErrorNotification,
        }
    );

    const FilterByProgramNameInput = (
        <Search placeholder="Program" allowClear onSearch={setSearchTermProgramName} style={{ width: 130 }} />
    );

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: 400 }} />
    );

    const FilterByDomainInput = (
        <Select placeholder="Domeniu" style={{ width: 100 }} onChange={setSearchTermDomain} defaultValue={""}>
                            <Option value="">Toate</Option>
                            <Option value="CTI">CTI</Option>
                            <Option value="IS">IS</Option>
                            <Option value="IM">IM</Option>
                        </Select>
    );

    const FilterByYearInput = (
        <Select placeholder="An" style={{ width: 100 }} onChange={setSearchTermYear} defaultValue={StudentYearEnum.NotApplicable}>
                            <Option value={StudentYearEnum.NotApplicable}>Toate</Option>
                            <Option value={StudentYearEnum.M1}>M1</Option>
                            <Option value={StudentYearEnum.M2}>M2</Option>
                        </Select>
    );

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name'
        },
        {
            width: 135,
            title: FilterByDomainInput,
            dataIndex: 'domain',
            key: 'domain',
        },
        {
            width: 160,
            title: FilterByProgramNameInput,
            dataIndex: 'series',
            render: (_: any, record: Subject) => {
              return record.specialization?.name;
          }
        },
        {
            width: 135,
            title: FilterByYearInput,
            dataIndex: 'year',
            key: 'year',
        },
        {
            width: 135,
            title: 'Semestru',
            dataIndex: 'semester',
            render: (_: any, record: Subject) => {
                if (record.semester === SemesterEnum.FirstSemester) {
                    return "Semestrul 1";
                }
                else if (record.semester === SemesterEnum.SecondSemesterFirstSubject) {
                    return "Semestrul 2";
                }
            }
        },
        {
            width: 100,
            title: 'Număr minim',
            dataIndex: 'minimumSpots',
            key: 'minimumSpots',
            inputType: 'number',
            editable: true,
        },
        {
          width: 100,
          title: 'Număr studenti inrolati initial',
          dataIndex: 'initialEnrolledStudents',
          key: 'initialEnrolledStudents',
          inputType: 'number',
          // editable: true,
        },
        {
            width: 100,
            title: 'Număr studenti inrolati',
            dataIndex: 'enrolledStudents',
            key: 'enrolledStudents',
            inputType: 'number',
            // editable: true,
        },
        {
            width: 100,
            title: 'Număr maxim',
            dataIndex: 'maximumSpots',
            key: 'maximumSpots',
            inputType: 'number',
            editable: true,
        },
        {
            width: 135,
            title: 'Actiuni',
            dataIndex: 'operation',
            render: (_: any, record: Subject) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link onClick={() => save(record.id!)} style={{ marginRight: 8 }}>
                    Salvare
                  </Typography.Link>
                  <Popconfirm title="Sigur anulezi?" onConfirm={cancel} cancelText="Renunta" okText="Da">
                    <a>Anulare</a>
                  </Popconfirm>
                </span>
              ) : (
                <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                  Editare
                </Typography.Link>
              );
            },
          },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: Subject) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Form form={form} component={false}>
            <div
            style={{
                height: "calc(100vh - 180px)",
                overflow: "auto"
            }}
            >
                <Table
                    className={styles.usersTable}
                    components={{
                        body: {
                        cell: EditableCell,
                        },
                    }}
                    dataSource={data ?? new Array<Subject>()}
                    columns={mergedColumns}
                    pagination={false}
                    rowKey={record => record.id ?? ''}
                    loading={isLoading}
                    sticky={true}
                    />
            </div>
            </Form>
        </Layout>
    </div>
}

export default AdminMaster;