import 'antd/dist/antd.min.css';
import { ConfigCatProvider, LogLevel, createConsoleLogger } from "configcat-react";
import fetchIntercept from 'fetch-intercept';
import { StatusCodes } from 'http-status-codes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import './App.scss';
import Router from './Router/Router';
import { getTokenExpire } from './utils/utilFunctions';

fetchIntercept.register({
  response: (response) => {
    const expire = getTokenExpire();
    const now = Date.now() / 1000;

    if (response.request?.headers && response.request.headers.has('authorization') &&
      response.status === StatusCodes.UNAUTHORIZED && (!expire || expire < now)) {
      localStorage.removeItem("token");
      window.location.href = '/?expired=true';
    }

    return response;
  },
  responseError: (error) => {
    const expire = getTokenExpire();
    const now = Date.now() / 1000;

    if (error.request?.headers && error.request.headers.has('authorization') && (!expire || expire < now)) {
      localStorage.removeItem("token");
      window.location.href = '/?expired=true';
    }

    return Promise.reject(error);
  }
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['student']
};

const rootReducer = combineReducers({
  // student: studentReducer
})

const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer);
const persistor = persistStore(store);
const queryClient = new QueryClient();

function App() {
  const logger = createConsoleLogger(LogLevel.Info);

  return (
    <ConfigCatProvider sdkKey={process.env.REACT_APP_OPTIONALS_SDK_KEY!} options={{ logger }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <div className='App'>
              <Router />
            </div>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ConfigCatProvider>
  );
}

export default App;
