/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SpecializationEnum {
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    A = 'A',
    B = 'B',
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    NotApplicable = 'NotApplicable'
}

export function SpecializationEnumFromJSON(json: any): SpecializationEnum {
    return SpecializationEnumFromJSONTyped(json, false);
}

export function SpecializationEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecializationEnum {
    return json as SpecializationEnum;
}

export function SpecializationEnumToJSON(value?: SpecializationEnum | null): any {
    return value as any;
}

