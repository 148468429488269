/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface ChangeRequestReportDTO
 */
export interface ChangeRequestReportDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestReportDTO
     */
    isApproved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestReportDTO
     */
    name?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof ChangeRequestReportDTO
     */
    studentYear?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestReportDTO
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestReportDTO
     */
    series?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestReportDTO
     */
    group?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChangeRequestReportDTO
     */
    averageGrade?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestReportDTO
     */
    sourceSubject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestReportDTO
     */
    destinationSubject?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestReportDTO
     */
    options?: Array<string> | null;
}

export function ChangeRequestReportDTOFromJSON(json: any): ChangeRequestReportDTO {
    return ChangeRequestReportDTOFromJSONTyped(json, false);
}

export function ChangeRequestReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isApproved': !exists(json, 'isApproved') ? undefined : json['isApproved'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'studentYear': !exists(json, 'studentYear') ? undefined : StudentYearEnumFromJSON(json['studentYear']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'averageGrade': !exists(json, 'averageGrade') ? undefined : json['averageGrade'],
        'sourceSubject': !exists(json, 'sourceSubject') ? undefined : json['sourceSubject'],
        'destinationSubject': !exists(json, 'destinationSubject') ? undefined : json['destinationSubject'],
        'options': !exists(json, 'options') ? undefined : json['options'],
    };
}

export function ChangeRequestReportDTOToJSON(value?: ChangeRequestReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isApproved': value.isApproved,
        'name': value.name,
        'studentYear': StudentYearEnumToJSON(value.studentYear),
        'domain': value.domain,
        'series': value.series,
        'group': value.group,
        'averageGrade': value.averageGrade,
        'sourceSubject': value.sourceSubject,
        'destinationSubject': value.destinationSubject,
        'options': value.options,
    };
}

