/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApproveChangeRequestsSimulationResponseDTO
 */
export interface ApproveChangeRequestsSimulationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    numberOfSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    remainingSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    numberOfStudentsAccepted?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    numberOfStudentsLeaving?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    numberOfRequestsToChangeSubject?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    numberOfRequestsToTakeSubject?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveChangeRequestsSimulationResponseDTO
     */
    enrolledStudents?: number;
}

export function ApproveChangeRequestsSimulationResponseDTOFromJSON(json: any): ApproveChangeRequestsSimulationResponseDTO {
    return ApproveChangeRequestsSimulationResponseDTOFromJSONTyped(json, false);
}

export function ApproveChangeRequestsSimulationResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveChangeRequestsSimulationResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numberOfSpots': !exists(json, 'numberOfSpots') ? undefined : json['numberOfSpots'],
        'remainingSpots': !exists(json, 'remainingSpots') ? undefined : json['remainingSpots'],
        'numberOfStudentsAccepted': !exists(json, 'numberOfStudentsAccepted') ? undefined : json['numberOfStudentsAccepted'],
        'numberOfStudentsLeaving': !exists(json, 'numberOfStudentsLeaving') ? undefined : json['numberOfStudentsLeaving'],
        'numberOfRequestsToChangeSubject': !exists(json, 'numberOfRequestsToChangeSubject') ? undefined : json['numberOfRequestsToChangeSubject'],
        'numberOfRequestsToTakeSubject': !exists(json, 'numberOfRequestsToTakeSubject') ? undefined : json['numberOfRequestsToTakeSubject'],
        'enrolledStudents': !exists(json, 'enrolledStudents') ? undefined : json['enrolledStudents'],
    };
}

export function ApproveChangeRequestsSimulationResponseDTOToJSON(value?: ApproveChangeRequestsSimulationResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'numberOfSpots': value.numberOfSpots,
        'remainingSpots': value.remainingSpots,
        'numberOfStudentsAccepted': value.numberOfStudentsAccepted,
        'numberOfStudentsLeaving': value.numberOfStudentsLeaving,
        'numberOfRequestsToChangeSubject': value.numberOfRequestsToChangeSubject,
        'numberOfRequestsToTakeSubject': value.numberOfRequestsToTakeSubject,
        'enrolledStudents': value.enrolledStudents,
    };
}

