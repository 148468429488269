import { Select } from "antd";
import styled from "@emotion/styled";
import { StudentYearEnum, Role } from "../Api";

const { Option } = Select;

export const userSkills = [
    <Option key={1} value={"C++"}>C++</Option>, 
    <Option key={2} value={"Java"}>Java</Option>, 
    <Option key={3} value={"Python"}>Python</Option>,
    <Option key={4} value={"C#"}>C#</Option>
];

export const userYears = [
    StudentYearEnum.L1,
    StudentYearEnum.L2, 
    StudentYearEnum.L3,
    StudentYearEnum.L4,
    StudentYearEnum.M1, 
    StudentYearEnum.M2, 
    StudentYearEnum.D1, 
    StudentYearEnum.D2, 
    StudentYearEnum.D3, 
    StudentYearEnum.D4,
    StudentYearEnum.D5
];

export const routeNames = {
    
}

export const urlPattern = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i);

export const phonePattern = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i);

export const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

export const faxPattern = new RegExp(/[+]?[0-9]{1,3}[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,}/i);

export const rolesArray = [
    Role.Admin,
    Role.Dean,
    Role.FacultyAdmin,
    Role.InternshipAdmin,
    Role.Professor,
    Role.Rector,
    Role.GeneralDirector,
    Role.EconomicDirector,
    Role.LegalCounselor,
    Role.PreventiveFinancialControl,
    Role.UniversityAccordResponsible
]

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export enum ChooseAction {
    ChooseSubject = 'ChooseSubject',
    ChooseSpecialization = 'ChooseSpecialization',
    SpecializationRepartition = 'SpecializationRepartition',
    SubjectRepartition = 'SubjectRepartition'
}

export const DroppableStyles = styled("div")`
  padding: 10px;
  border-radius: 6px;
  width: 100%;
`;