import { ApiChangeRequestUpdateAvailableSpotsPutRequest, ChangeRequestApi } from "../Api/apis";
import { ShortSemesterEnum, SubjectTypeEnum, ChangeRequestDTO, SpecialChangeRequestDTO, StudentYearEnum } from "../Api/models";
import { getAuthConfiguration } from "./configuration";


const AuthChangeRequestApiFactory = () => new ChangeRequestApi(getAuthConfiguration());

export const GetChangeRequest = () => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetChangeRequestsGet();
}

export const GetSourceSubjects = () => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetSourceSubjectsGet();
}

export const GetDestinationSubject = (semester?: ShortSemesterEnum, type?: SubjectTypeEnum, credits?: number, sourceSubjectName?: string) => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetDestinationSubjectsGet({semester, type, credits, sourceSubjectName});
}

export const PostChangeRequest = (changeRequestDTO: ChangeRequestDTO) => {
    return AuthChangeRequestApiFactory().apiChangeRequestPostChangeRequestPost({changeRequestDTO});
}

export const PostSpecialChangeRequest = (specialChangeRequestDTO: SpecialChangeRequestDTO) => {
    return AuthChangeRequestApiFactory().apiChangeRequestPostSpecialChangeRequestPost({specialChangeRequestDTO});
}

export const DeleteChangeRequest = (id: string) => {
    return AuthChangeRequestApiFactory().apiChangeRequestDeleteChangeRequestIdDelete({id});
}

export const DeleteSpecialChangeRequest = (id: string) => {
    return AuthChangeRequestApiFactory().apiChangeRequestDeleteSpecialChangeRequestIdDelete({id});
}

export const GetChangeRequestResponse = () => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetChangeRequestResponsesGet();
}

export const GetSpecialChangeRequestResponse = () => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetSpecialChangeRequestResponsesGet();
}

export const GetAllChangeRequestSubjectsFiltered = (name: string = '', domain: string = '', year: StudentYearEnum = StudentYearEnum.NotApplicable) => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetAllSubjectsGet({domain, name, year});
}

export const AdminUpdateAvailablePositions = (data: ApiChangeRequestUpdateAvailableSpotsPutRequest) => {
    return AuthChangeRequestApiFactory().apiChangeRequestUpdateAvailableSpotsPut(data);
}

export const GetRemainingChanges = () => {
    return AuthChangeRequestApiFactory().apiChangeRequestGetRemainingChangesGet();
}

export const ExportChangeRequestsResults = () => {
    return AuthChangeRequestApiFactory().apiChangeRequestExportChangeRequestsResultsPost();
}
