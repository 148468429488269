import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {useState} from "react";
import Layout from "../../Containers/Layout";
import {
    ChooseSpecialization,
    GetAllSpecialization,
    GetSpecializationChoices
} from "../../Requests/specialization-requests";
import { ChooseAction } from "../../utils/constants";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import CustomList from "./CustomList";


const ChooseSpecializations = () => {
    const { t } = useTranslation();
    const [available, setIsAvailable] = useState(true);

    const openSpecializationErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchSpecializationsError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data } = useQuery(['getSpecializations'],
        () => {
            return GetAllSpecialization();
        }, {
        onError: (error) => {
            const errorWithResponse = error as {status?: number};
            if (errorWithResponse.status) {
                const statusCode = errorWithResponse.status;
                if (statusCode === 403) {
                    setIsAvailable(false);
                } else {
                    openSpecializationErrorNotification(error);
                }
            } else {
                openSpecializationErrorNotification(error);
            }
        },
        onSuccess: () => {
            setIsAvailable(true);
        }
    });

    return available ? (
        <Layout>
            <h1>{t('optionals.chooseSpecializations')}</h1>
            {!!(data?.length) && data?.length > 0 ?
                <div>
                    <h4>Adaugă opțiunile dorite în ordinea preferințelor. Este recomandat să adaugi mai multe opțiuni pentru siguranță.</h4>
                    <h4>Dacă nu completezi opțiunile, vei fi repartizat automat unde mai rămân locuri.</h4>
                    <CustomList
                        data={data}
                        handleSave={ChooseSpecialization}
                        choices={GetSpecializationChoices}
                        type={ChooseAction.ChooseSpecialization}
                        semester={null}
                        title={null}
                    />
                </div>
                :
                <div>
                    <h4>Pentru anul acesta nu ai de ales specializări / direcții!</h4>
                </div>
            }


        </Layout>
    ) : ( 
        <Layout>
            <h2>{t('optionals.specializationSelectionNotEnabled')}</h2>
        </Layout>
    );
}

export default ChooseSpecializations;