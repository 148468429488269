/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNumberOfSpotsDTO
 */
export interface UpdateNumberOfSpotsDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateNumberOfSpotsDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNumberOfSpotsDTO
     */
    availableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNumberOfSpotsDTO
     */
    enrolledStudents?: number;
}

export function UpdateNumberOfSpotsDTOFromJSON(json: any): UpdateNumberOfSpotsDTO {
    return UpdateNumberOfSpotsDTOFromJSONTyped(json, false);
}

export function UpdateNumberOfSpotsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNumberOfSpotsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'availableSpots': !exists(json, 'availableSpots') ? undefined : json['availableSpots'],
        'enrolledStudents': !exists(json, 'enrolledStudents') ? undefined : json['enrolledStudents'],
    };
}

export function UpdateNumberOfSpotsDTOToJSON(value?: UpdateNumberOfSpotsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'availableSpots': value.availableSpots,
        'enrolledStudents': value.enrolledStudents,
    };
}

