/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';

/**
 * 
 * @export
 * @interface RepartitionResults
 */
export interface RepartitionResults {
    /**
     * 
     * @type {string}
     * @memberof RepartitionResults
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepartitionResults
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RepartitionResults
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof RepartitionResults
     */
    studentId?: string;
    /**
     * 
     * @type {AcademicUser}
     * @memberof RepartitionResults
     */
    student?: AcademicUser;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    specializationChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    firstSemesterSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    firstSemesterSecondSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    secondSemesterFirstSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    secondSemesterSecondSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    secondSemesterThirdSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    specializationRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    firstSemesterSubjectRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    firstSemesterSecondSubjectRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    secondSemesterFirstSubjectRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    secondSemesterSecondSubjectRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepartitionResults
     */
    secondSemesterThirdSubjectRepartition?: Array<string> | null;
}

export function RepartitionResultsFromJSON(json: any): RepartitionResults {
    return RepartitionResultsFromJSONTyped(json, false);
}

export function RepartitionResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepartitionResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'specializationChoices': !exists(json, 'specializationChoices') ? undefined : json['specializationChoices'],
        'firstSemesterSubjectChoices': !exists(json, 'firstSemesterSubjectChoices') ? undefined : json['firstSemesterSubjectChoices'],
        'firstSemesterSecondSubjectChoices': !exists(json, 'firstSemesterSecondSubjectChoices') ? undefined : json['firstSemesterSecondSubjectChoices'],
        'secondSemesterFirstSubjectChoices': !exists(json, 'secondSemesterFirstSubjectChoices') ? undefined : json['secondSemesterFirstSubjectChoices'],
        'secondSemesterSecondSubjectChoices': !exists(json, 'secondSemesterSecondSubjectChoices') ? undefined : json['secondSemesterSecondSubjectChoices'],
        'secondSemesterThirdSubjectChoices': !exists(json, 'secondSemesterThirdSubjectChoices') ? undefined : json['secondSemesterThirdSubjectChoices'],
        'specializationRepartition': !exists(json, 'specializationRepartition') ? undefined : json['specializationRepartition'],
        'firstSemesterSubjectRepartition': !exists(json, 'firstSemesterSubjectRepartition') ? undefined : json['firstSemesterSubjectRepartition'],
        'firstSemesterSecondSubjectRepartition': !exists(json, 'firstSemesterSecondSubjectRepartition') ? undefined : json['firstSemesterSecondSubjectRepartition'],
        'secondSemesterFirstSubjectRepartition': !exists(json, 'secondSemesterFirstSubjectRepartition') ? undefined : json['secondSemesterFirstSubjectRepartition'],
        'secondSemesterSecondSubjectRepartition': !exists(json, 'secondSemesterSecondSubjectRepartition') ? undefined : json['secondSemesterSecondSubjectRepartition'],
        'secondSemesterThirdSubjectRepartition': !exists(json, 'secondSemesterThirdSubjectRepartition') ? undefined : json['secondSemesterThirdSubjectRepartition'],
    };
}

export function RepartitionResultsToJSON(value?: RepartitionResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'studentId': value.studentId,
        'student': AcademicUserToJSON(value.student),
        'specializationChoices': value.specializationChoices,
        'firstSemesterSubjectChoices': value.firstSemesterSubjectChoices,
        'firstSemesterSecondSubjectChoices': value.firstSemesterSecondSubjectChoices,
        'secondSemesterFirstSubjectChoices': value.secondSemesterFirstSubjectChoices,
        'secondSemesterSecondSubjectChoices': value.secondSemesterSecondSubjectChoices,
        'secondSemesterThirdSubjectChoices': value.secondSemesterThirdSubjectChoices,
        'specializationRepartition': value.specializationRepartition,
        'firstSemesterSubjectRepartition': value.firstSemesterSubjectRepartition,
        'firstSemesterSecondSubjectRepartition': value.firstSemesterSecondSubjectRepartition,
        'secondSemesterFirstSubjectRepartition': value.secondSemesterFirstSubjectRepartition,
        'secondSemesterSecondSubjectRepartition': value.secondSemesterSecondSubjectRepartition,
        'secondSemesterThirdSubjectRepartition': value.secondSemesterThirdSubjectRepartition,
    };
}

