/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShortSemesterEnum {
    FirstSemester = 'FirstSemester',
    SecondSemester = 'SecondSemester'
}

export function ShortSemesterEnumFromJSON(json: any): ShortSemesterEnum {
    return ShortSemesterEnumFromJSONTyped(json, false);
}

export function ShortSemesterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortSemesterEnum {
    return json as ShortSemesterEnum;
}

export function ShortSemesterEnumToJSON(value?: ShortSemesterEnum | null): any {
    return value as any;
}

