/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleSubjectPlanDTO,
    SimpleSubjectPlanDTOFromJSON,
    SimpleSubjectPlanDTOFromJSONTyped,
    SimpleSubjectPlanDTOToJSON,
} from './SimpleSubjectPlanDTO';

/**
 * 
 * @export
 * @interface SpecialChangeRequestDTO
 */
export interface SpecialChangeRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequestDTO
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequestDTO
     */
    studentId?: string;
    /**
     * 
     * @type {SimpleSubjectPlanDTO}
     * @memberof SpecialChangeRequestDTO
     */
    sourceSubjectPlan?: SimpleSubjectPlanDTO;
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequestDTO
     */
    message?: string | null;
}

export function SpecialChangeRequestDTOFromJSON(json: any): SpecialChangeRequestDTO {
    return SpecialChangeRequestDTOFromJSONTyped(json, false);
}

export function SpecialChangeRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialChangeRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'sourceSubjectPlan': !exists(json, 'sourceSubjectPlan') ? undefined : SimpleSubjectPlanDTOFromJSON(json['sourceSubjectPlan']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function SpecialChangeRequestDTOToJSON(value?: SpecialChangeRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'studentId': value.studentId,
        'sourceSubjectPlan': SimpleSubjectPlanDTOToJSON(value.sourceSubjectPlan),
        'message': value.message,
    };
}

