/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    SubjectPlan,
    SubjectPlanFromJSON,
    SubjectPlanFromJSONTyped,
    SubjectPlanToJSON,
} from './SubjectPlan';

/**
 * 
 * @export
 * @interface ChangeRequestsResults
 */
export interface ChangeRequestsResults {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestsResults
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChangeRequestsResults
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ChangeRequestsResults
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestsResults
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestsResults
     */
    sourceSubjectPlanId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestsResults
     */
    destinationSubjectPlanIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestsResults
     */
    acceptedDestinationSubjectPlanId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestsResults
     */
    isApproved?: boolean;
    /**
     * 
     * @type {AcademicUser}
     * @memberof ChangeRequestsResults
     */
    student?: AcademicUser;
    /**
     * 
     * @type {SubjectPlan}
     * @memberof ChangeRequestsResults
     */
    sourceSubjectPlan?: SubjectPlan;
    /**
     * 
     * @type {SubjectPlan}
     * @memberof ChangeRequestsResults
     */
    acceptedDestinationSubjectPlan?: SubjectPlan;
}

export function ChangeRequestsResultsFromJSON(json: any): ChangeRequestsResults {
    return ChangeRequestsResultsFromJSONTyped(json, false);
}

export function ChangeRequestsResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestsResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'sourceSubjectPlanId': !exists(json, 'sourceSubjectPlanId') ? undefined : json['sourceSubjectPlanId'],
        'destinationSubjectPlanIds': !exists(json, 'destinationSubjectPlanIds') ? undefined : json['destinationSubjectPlanIds'],
        'acceptedDestinationSubjectPlanId': !exists(json, 'acceptedDestinationSubjectPlanId') ? undefined : json['acceptedDestinationSubjectPlanId'],
        'isApproved': !exists(json, 'isApproved') ? undefined : json['isApproved'],
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'sourceSubjectPlan': !exists(json, 'sourceSubjectPlan') ? undefined : SubjectPlanFromJSON(json['sourceSubjectPlan']),
        'acceptedDestinationSubjectPlan': !exists(json, 'acceptedDestinationSubjectPlan') ? undefined : SubjectPlanFromJSON(json['acceptedDestinationSubjectPlan']),
    };
}

export function ChangeRequestsResultsToJSON(value?: ChangeRequestsResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'studentId': value.studentId,
        'sourceSubjectPlanId': value.sourceSubjectPlanId,
        'destinationSubjectPlanIds': value.destinationSubjectPlanIds,
        'acceptedDestinationSubjectPlanId': value.acceptedDestinationSubjectPlanId,
        'isApproved': value.isApproved,
        'student': AcademicUserToJSON(value.student),
        'sourceSubjectPlan': SubjectPlanToJSON(value.sourceSubjectPlan),
        'acceptedDestinationSubjectPlan': SubjectPlanToJSON(value.acceptedDestinationSubjectPlan),
    };
}

