/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleSubjectPlanDTO,
    SimpleSubjectPlanDTOFromJSON,
    SimpleSubjectPlanDTOFromJSONTyped,
    SimpleSubjectPlanDTOToJSON,
} from './SimpleSubjectPlanDTO';

/**
 * 
 * @export
 * @interface ChangeRequestStatusDTO
 */
export interface ChangeRequestStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestStatusDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestStatusDTO
     */
    studentId?: string;
    /**
     * 
     * @type {SimpleSubjectPlanDTO}
     * @memberof ChangeRequestStatusDTO
     */
    sourceSubjectPlan?: SimpleSubjectPlanDTO;
    /**
     * 
     * @type {SimpleSubjectPlanDTO}
     * @memberof ChangeRequestStatusDTO
     */
    acceptedDestinationSubjectPlan?: SimpleSubjectPlanDTO;
    /**
     * 
     * @type {Array<SimpleSubjectPlanDTO>}
     * @memberof ChangeRequestStatusDTO
     */
    rejectedDestinationSubjectPlans?: Array<SimpleSubjectPlanDTO> | null;
}

export function ChangeRequestStatusDTOFromJSON(json: any): ChangeRequestStatusDTO {
    return ChangeRequestStatusDTOFromJSONTyped(json, false);
}

export function ChangeRequestStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestStatusDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'sourceSubjectPlan': !exists(json, 'sourceSubjectPlan') ? undefined : SimpleSubjectPlanDTOFromJSON(json['sourceSubjectPlan']),
        'acceptedDestinationSubjectPlan': !exists(json, 'acceptedDestinationSubjectPlan') ? undefined : SimpleSubjectPlanDTOFromJSON(json['acceptedDestinationSubjectPlan']),
        'rejectedDestinationSubjectPlans': !exists(json, 'rejectedDestinationSubjectPlans') ? undefined : (json['rejectedDestinationSubjectPlans'] === null ? null : (json['rejectedDestinationSubjectPlans'] as Array<any>).map(SimpleSubjectPlanDTOFromJSON)),
    };
}

export function ChangeRequestStatusDTOToJSON(value?: ChangeRequestStatusDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'studentId': value.studentId,
        'sourceSubjectPlan': SimpleSubjectPlanDTOToJSON(value.sourceSubjectPlan),
        'acceptedDestinationSubjectPlan': SimpleSubjectPlanDTOToJSON(value.acceptedDestinationSubjectPlan),
        'rejectedDestinationSubjectPlans': value.rejectedDestinationSubjectPlans === undefined ? undefined : (value.rejectedDestinationSubjectPlans === null ? null : (value.rejectedDestinationSubjectPlans as Array<any>).map(SimpleSubjectPlanDTOToJSON)),
    };
}

