/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeRequestReportDTO,
    ChangeRequestReportDTOFromJSON,
    ChangeRequestReportDTOToJSON,
    ChangeRequestReportRequestDTO,
    ChangeRequestReportRequestDTOFromJSON,
    ChangeRequestReportRequestDTOToJSON,
    ChangeRequestsStatisticsReportDTO,
    ChangeRequestsStatisticsReportDTOFromJSON,
    ChangeRequestsStatisticsReportDTOToJSON,
    OptionalsReportDTO,
    OptionalsReportDTOFromJSON,
    OptionalsReportDTOToJSON,
    OptionalsReportsDTO,
    OptionalsReportsDTOFromJSON,
    OptionalsReportsDTOToJSON,
    OptionalsStatisticsReportDTO,
    OptionalsStatisticsReportDTOFromJSON,
    OptionalsStatisticsReportDTOToJSON,
    OptionalsStatisticsReportRequestDTO,
    OptionalsStatisticsReportRequestDTOFromJSON,
    OptionalsStatisticsReportRequestDTOToJSON,
    SpecializationReportDTO,
    SpecializationReportDTOFromJSON,
    SpecializationReportDTOToJSON,
} from '../models';

export interface ApiOptionalsReportsGenerateChangeRequestReportPostRequest {
    changeRequestReportRequestDTO?: ChangeRequestReportRequestDTO;
}

export interface ApiOptionalsReportsGenerateChangeRequestsStatisticsReportPostRequest {
    optionalsReportsDTO?: OptionalsReportsDTO;
}

export interface ApiOptionalsReportsGenerateOptionalsReportPostRequest {
    optionalsReportsDTO?: OptionalsReportsDTO;
}

export interface ApiOptionalsReportsGenerateOptionalsStatisticsReportPostRequest {
    optionalsStatisticsReportRequestDTO?: OptionalsStatisticsReportRequestDTO;
}

export interface ApiOptionalsReportsGenerateSpecializationReportPostRequest {
    optionalsReportsDTO?: OptionalsReportsDTO;
}

/**
 * 
 */
export class OptionalsReportsApi extends runtime.BaseAPI {

    /**
     */
    async apiOptionalsReportsGenerateChangeRequestReportPostRaw(requestParameters: ApiOptionalsReportsGenerateChangeRequestReportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ChangeRequestReportDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/OptionalsReports/GenerateChangeRequestReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRequestReportRequestDTOToJSON(requestParameters.changeRequestReportRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChangeRequestReportDTOFromJSON));
    }

    /**
     */
    async apiOptionalsReportsGenerateChangeRequestReportPost(requestParameters: ApiOptionalsReportsGenerateChangeRequestReportPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ChangeRequestReportDTO>> {
        const response = await this.apiOptionalsReportsGenerateChangeRequestReportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOptionalsReportsGenerateChangeRequestsStatisticsReportPostRaw(requestParameters: ApiOptionalsReportsGenerateChangeRequestsStatisticsReportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ChangeRequestsStatisticsReportDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/OptionalsReports/GenerateChangeRequestsStatisticsReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionalsReportsDTOToJSON(requestParameters.optionalsReportsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChangeRequestsStatisticsReportDTOFromJSON));
    }

    /**
     */
    async apiOptionalsReportsGenerateChangeRequestsStatisticsReportPost(requestParameters: ApiOptionalsReportsGenerateChangeRequestsStatisticsReportPostRequest = {}, initOverrides?: RequestInit): Promise<Array<ChangeRequestsStatisticsReportDTO>> {
        const response = await this.apiOptionalsReportsGenerateChangeRequestsStatisticsReportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOptionalsReportsGenerateOptionalsReportPostRaw(requestParameters: ApiOptionalsReportsGenerateOptionalsReportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OptionalsReportDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/OptionalsReports/GenerateOptionalsReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionalsReportsDTOToJSON(requestParameters.optionalsReportsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionalsReportDTOFromJSON));
    }

    /**
     */
    async apiOptionalsReportsGenerateOptionalsReportPost(requestParameters: ApiOptionalsReportsGenerateOptionalsReportPostRequest = {}, initOverrides?: RequestInit): Promise<Array<OptionalsReportDTO>> {
        const response = await this.apiOptionalsReportsGenerateOptionalsReportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOptionalsReportsGenerateOptionalsStatisticsReportPostRaw(requestParameters: ApiOptionalsReportsGenerateOptionalsStatisticsReportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OptionalsStatisticsReportDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/OptionalsReports/GenerateOptionalsStatisticsReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionalsStatisticsReportRequestDTOToJSON(requestParameters.optionalsStatisticsReportRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionalsStatisticsReportDTOFromJSON));
    }

    /**
     */
    async apiOptionalsReportsGenerateOptionalsStatisticsReportPost(requestParameters: ApiOptionalsReportsGenerateOptionalsStatisticsReportPostRequest = {}, initOverrides?: RequestInit): Promise<Array<OptionalsStatisticsReportDTO>> {
        const response = await this.apiOptionalsReportsGenerateOptionalsStatisticsReportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOptionalsReportsGenerateSpecializationReportPostRaw(requestParameters: ApiOptionalsReportsGenerateSpecializationReportPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SpecializationReportDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/OptionalsReports/GenerateSpecializationReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionalsReportsDTOToJSON(requestParameters.optionalsReportsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecializationReportDTOFromJSON));
    }

    /**
     */
    async apiOptionalsReportsGenerateSpecializationReportPost(requestParameters: ApiOptionalsReportsGenerateSpecializationReportPostRequest = {}, initOverrides?: RequestInit): Promise<Array<SpecializationReportDTO>> {
        const response = await this.apiOptionalsReportsGenerateSpecializationReportPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
