/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SemesterEnum {
    FirstSemester = 'FirstSemester',
    SecondSemesterFirstSubject = 'SecondSemesterFirstSubject',
    SecondSemesterSecondSubject = 'SecondSemesterSecondSubject',
    All = 'All',
    FirstSemesterSecondSubject = 'FirstSemesterSecondSubject',
    SecondSemesterThirdSubject = 'SecondSemesterThirdSubject'
}

export function SemesterEnumFromJSON(json: any): SemesterEnum {
    return SemesterEnumFromJSONTyped(json, false);
}

export function SemesterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SemesterEnum {
    return json as SemesterEnum;
}

export function SemesterEnumToJSON(value?: SemesterEnum | null): any {
    return value as any;
}

