/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

/**
 * 
 */
export class ImportOptionalsApi extends runtime.BaseAPI {

    /**
     */
    async apiImportOptionalsDeleteOldCurriculumPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/DeleteOldCurriculum`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsDeleteOldCurriculumPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsDeleteOldCurriculumPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsDeleteOldOptionalsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/DeleteOldOptionals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsDeleteOldOptionalsPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsDeleteOldOptionalsPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportMastersCurriculumPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/FetchAndImportMastersCurriculum`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportMastersCurriculumPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsFetchAndImportMastersCurriculumPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportMastersStudentsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/FetchAndImportMastersStudents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportMastersStudentsPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsFetchAndImportMastersStudentsPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportUndergraduateCurriculumPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/FetchAndImportUndergraduateCurriculum`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportUndergraduateCurriculumPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsFetchAndImportUndergraduateCurriculumPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportUndergraduateStudentsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/FetchAndImportUndergraduateStudents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsFetchAndImportUndergraduateStudentsPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsFetchAndImportUndergraduateStudentsPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsUpdateFacultiesPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/UpdateFaculties`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsUpdateFacultiesPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsUpdateFacultiesPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsUpdateNumberOfChangeRequestsAfterRepartitionPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/UpdateNumberOfChangeRequestsAfterRepartition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsUpdateNumberOfChangeRequestsAfterRepartitionPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsUpdateNumberOfChangeRequestsAfterRepartitionPostRaw(initOverrides);
    }

    /**
     */
    async apiImportOptionalsUpdateUsersPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ImportOptionals/UpdateUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiImportOptionalsUpdateUsersPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiImportOptionalsUpdateUsersPostRaw(initOverrides);
    }

}
