/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalStudentData,
    AdditionalStudentDataFromJSON,
    AdditionalStudentDataFromJSONTyped,
    AdditionalStudentDataToJSON,
} from './AdditionalStudentData';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    RepartitionResults,
    RepartitionResultsFromJSON,
    RepartitionResultsFromJSONTyped,
    RepartitionResultsToJSON,
} from './RepartitionResults';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface AcademicUser
 */
export interface AcademicUser {
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AcademicUser
     */
    cursId?: number;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    departmentId?: string;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof AcademicUser
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    group?: string | null;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AcademicUser
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {Department}
     * @memberof AcademicUser
     */
    department?: Department;
    /**
     * 
     * @type {AdditionalStudentData}
     * @memberof AcademicUser
     */
    additionalStudentData?: AdditionalStudentData;
    /**
     * 
     * @type {RepartitionResults}
     * @memberof AcademicUser
     */
    repartitionResults?: RepartitionResults;
}

export function AcademicUserFromJSON(json: any): AcademicUser {
    return AcademicUserFromJSONTyped(json, false);
}

export function AcademicUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcademicUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cursId': !exists(json, 'cursId') ? undefined : json['cursId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'group': !exists(json, 'group') ? undefined : json['group'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
        'additionalStudentData': !exists(json, 'additionalStudentData') ? undefined : AdditionalStudentDataFromJSON(json['additionalStudentData']),
        'repartitionResults': !exists(json, 'repartitionResults') ? undefined : RepartitionResultsFromJSON(json['repartitionResults']),
    };
}

export function AcademicUserToJSON(value?: AcademicUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'cursId': value.cursId,
        'email': value.email,
        'departmentId': value.departmentId,
        'year': StudentYearEnumToJSON(value.year),
        'group': value.group,
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'department': DepartmentToJSON(value.department),
        'additionalStudentData': AdditionalStudentDataToJSON(value.additionalStudentData),
        'repartitionResults': RepartitionResultsToJSON(value.repartitionResults),
    };
}

