/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';

/**
 * 
 * @export
 * @interface Faculty
 */
export interface Faculty {
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    nameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    nameEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    acronym?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Faculty
     */
    externalId?: number;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    decisionNumber?: string | null;
    /**
     * 
     * @type {Array<Department>}
     * @memberof Faculty
     */
    departments?: Array<Department> | null;
}

export function FacultyFromJSON(json: any): Faculty {
    return FacultyFromJSONTyped(json, false);
}

export function FacultyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Faculty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'nameRo': !exists(json, 'nameRo') ? undefined : json['nameRo'],
        'nameEn': !exists(json, 'nameEn') ? undefined : json['nameEn'],
        'acronym': !exists(json, 'acronym') ? undefined : json['acronym'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'decisionNumber': !exists(json, 'decisionNumber') ? undefined : json['decisionNumber'],
        'departments': !exists(json, 'departments') ? undefined : (json['departments'] === null ? null : (json['departments'] as Array<any>).map(DepartmentFromJSON)),
    };
}

export function FacultyToJSON(value?: Faculty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'nameRo': value.nameRo,
        'nameEn': value.nameEn,
        'acronym': value.acronym,
        'externalId': value.externalId,
        'decisionNumber': value.decisionNumber,
        'departments': value.departments === undefined ? undefined : (value.departments === null ? null : (value.departments as Array<any>).map(DepartmentToJSON)),
    };
}

