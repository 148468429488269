/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeRequestReportRequestDTO
 */
export interface ChangeRequestReportRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestReportRequestDTO
     */
    masters?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestReportRequestDTO
     */
    onlyApproved?: boolean;
}

export function ChangeRequestReportRequestDTOFromJSON(json: any): ChangeRequestReportRequestDTO {
    return ChangeRequestReportRequestDTOFromJSONTyped(json, false);
}

export function ChangeRequestReportRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestReportRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'masters': !exists(json, 'masters') ? undefined : json['masters'],
        'onlyApproved': !exists(json, 'onlyApproved') ? undefined : json['onlyApproved'],
    };
}

export function ChangeRequestReportRequestDTOToJSON(value?: ChangeRequestReportRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'masters': value.masters,
        'onlyApproved': value.onlyApproved,
    };
}

