import 'antd/dist/antd.min.css';
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import Layout from '../../Containers/Layout';
import CustomForm from '../../CustomComponents/CustomForm';
import { theme } from '../../theme';
import { getBaseUrl } from '../../utils/urlUtils';
import { isUserLogged } from '../../utils/utilFunctions';

import styles from './Login.module.scss';


const Login = () => {
  const { t } = useTranslation();
  if (isUserLogged()) {
    return <Redirect to="/" />
  } else {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <CustomForm layout={theme.layout}
              boxshadow={"none"}
            >
              <div className={styles.contentACS} style={{ height: "230px" }}>
                {t('loginText.loginWithCredentials')}
                <a href={`${process.env.REACT_APP_AUTH_API_URL}/auth?clientAction=login&clientName=${getBaseUrl()}`}>{t('loginText.accessLink')}</a>
              </div>
            </CustomForm>
          </div>
        </div>
      </Layout >
    );
  }
};

export default Login;
