/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    SubjectPlan,
    SubjectPlanFromJSON,
    SubjectPlanFromJSONTyped,
    SubjectPlanToJSON,
} from './SubjectPlan';

/**
 * 
 * @export
 * @interface SpecialChangeRequest
 */
export interface SpecialChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequest
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SpecialChangeRequest
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SpecialChangeRequest
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequest
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequest
     */
    sourceSubjectPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialChangeRequest
     */
    message?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpecialChangeRequest
     */
    isApproved?: boolean;
    /**
     * 
     * @type {AcademicUser}
     * @memberof SpecialChangeRequest
     */
    student?: AcademicUser;
    /**
     * 
     * @type {SubjectPlan}
     * @memberof SpecialChangeRequest
     */
    sourceSubjectPlan?: SubjectPlan;
}

export function SpecialChangeRequestFromJSON(json: any): SpecialChangeRequest {
    return SpecialChangeRequestFromJSONTyped(json, false);
}

export function SpecialChangeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialChangeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'sourceSubjectPlanId': !exists(json, 'sourceSubjectPlanId') ? undefined : json['sourceSubjectPlanId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'isApproved': !exists(json, 'isApproved') ? undefined : json['isApproved'],
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'sourceSubjectPlan': !exists(json, 'sourceSubjectPlan') ? undefined : SubjectPlanFromJSON(json['sourceSubjectPlan']),
    };
}

export function SpecialChangeRequestToJSON(value?: SpecialChangeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'studentId': value.studentId,
        'sourceSubjectPlanId': value.sourceSubjectPlanId,
        'message': value.message,
        'isApproved': value.isApproved,
        'student': AcademicUserToJSON(value.student),
        'sourceSubjectPlan': SubjectPlanToJSON(value.sourceSubjectPlan),
    };
}

