/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SemesterEnum,
    SemesterEnumFromJSON,
    SemesterEnumFromJSONTyped,
    SemesterEnumToJSON,
} from './SemesterEnum';
import {
    SimulationType,
    SimulationTypeFromJSON,
    SimulationTypeFromJSONTyped,
    SimulationTypeToJSON,
} from './SimulationType';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface SimulationRequestDTO
 */
export interface SimulationRequestDTO {
    /**
     * 
     * @type {SemesterEnum}
     * @memberof SimulationRequestDTO
     */
    semester?: SemesterEnum;
    /**
     * 
     * @type {SimulationType}
     * @memberof SimulationRequestDTO
     */
    type?: SimulationType;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof SimulationRequestDTO
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof SimulationRequestDTO
     */
    domain?: string | null;
}

export function SimulationRequestDTOFromJSON(json: any): SimulationRequestDTO {
    return SimulationRequestDTOFromJSONTyped(json, false);
}

export function SimulationRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulationRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'semester': !exists(json, 'semester') ? undefined : SemesterEnumFromJSON(json['semester']),
        'type': !exists(json, 'type') ? undefined : SimulationTypeFromJSON(json['type']),
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
    };
}

export function SimulationRequestDTOToJSON(value?: SimulationRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'semester': SemesterEnumToJSON(value.semester),
        'type': SimulationTypeToJSON(value.type),
        'year': StudentYearEnumToJSON(value.year),
        'domain': value.domain,
    };
}

