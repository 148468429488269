/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExtendedViewStudentDTO,
    ExtendedViewStudentDTOFromJSON,
    ExtendedViewStudentDTOToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
} from '../models';

export interface ApiAcademicUserGetStudentByIdIdGetRequest {
    id: string;
}

/**
 * 
 */
export class AcademicUserApi extends runtime.BaseAPI {

    /**
     */
    async apiAcademicUserGetRolesForUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Role>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetRolesForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGet(initOverrides?: RequestInit): Promise<Array<Role>> {
        const response = await this.apiAcademicUserGetRolesForUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentByIdIdGetRaw(requestParameters: ApiAcademicUserGetStudentByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedViewStudentDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetStudentByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedViewStudentDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetStudentByIdIdGet(requestParameters: ApiAcademicUserGetStudentByIdIdGetRequest, initOverrides?: RequestInit): Promise<ExtendedViewStudentDTO> {
        const response = await this.apiAcademicUserGetStudentByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
