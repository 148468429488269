/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeRequestDTO,
    ChangeRequestDTOFromJSON,
    ChangeRequestDTOToJSON,
    ChangeRequestResponseDTO,
    ChangeRequestResponseDTOFromJSON,
    ChangeRequestResponseDTOToJSON,
    ChangeRequestsDTO,
    ChangeRequestsDTOFromJSON,
    ChangeRequestsDTOToJSON,
    ShortSemesterEnum,
    ShortSemesterEnumFromJSON,
    ShortSemesterEnumToJSON,
    SpecialChangeRequestDTO,
    SpecialChangeRequestDTOFromJSON,
    SpecialChangeRequestDTOToJSON,
    SpecialChangeRequestResponseDTO,
    SpecialChangeRequestResponseDTOFromJSON,
    SpecialChangeRequestResponseDTOToJSON,
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumToJSON,
    SubjectPlan,
    SubjectPlanFromJSON,
    SubjectPlanToJSON,
    SubjectPlanDTO,
    SubjectPlanDTOFromJSON,
    SubjectPlanDTOToJSON,
    SubjectTypeEnum,
    SubjectTypeEnumFromJSON,
    SubjectTypeEnumToJSON,
    UpdateNumberOfSpotsDTO,
    UpdateNumberOfSpotsDTOFromJSON,
    UpdateNumberOfSpotsDTOToJSON,
} from '../models';

export interface ApiChangeRequestDeleteChangeRequestBySourceIdSourceIdDeleteRequest {
    sourceId: string;
}

export interface ApiChangeRequestDeleteChangeRequestIdDeleteRequest {
    id: string;
}

export interface ApiChangeRequestDeleteSpecialChangeRequestIdDeleteRequest {
    id: string;
}

export interface ApiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDeleteRequest {
    sourceId: string;
}

export interface ApiChangeRequestGetAllSubjectsGetRequest {
    domain?: string;
    name?: string;
    year?: StudentYearEnum;
}

export interface ApiChangeRequestGetDestinationSubjectsGetRequest {
    semester?: ShortSemesterEnum;
    type?: SubjectTypeEnum;
    credits?: number;
    series?: string;
    specialization?: string;
    year?: StudentYearEnum;
    sourceSubjectName?: string;
}

export interface ApiChangeRequestPostChangeRequestPostRequest {
    changeRequestDTO: ChangeRequestDTO;
}

export interface ApiChangeRequestPostSpecialChangeRequestPostRequest {
    specialChangeRequestDTO: SpecialChangeRequestDTO;
}

export interface ApiChangeRequestUpdateAvailableSpotsPutRequest {
    updateNumberOfSpotsDTO?: UpdateNumberOfSpotsDTO;
}

/**
 * 
 */
export class ChangeRequestApi extends runtime.BaseAPI {

    /**
     */
    async apiChangeRequestDeleteChangeRequestBySourceIdSourceIdDeleteRaw(requestParameters: ApiChangeRequestDeleteChangeRequestBySourceIdSourceIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling apiChangeRequestDeleteChangeRequestBySourceIdSourceIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/DeleteChangeRequestBySourceId/{sourceId}`.replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestDeleteChangeRequestBySourceIdSourceIdDelete(requestParameters: ApiChangeRequestDeleteChangeRequestBySourceIdSourceIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestDeleteChangeRequestBySourceIdSourceIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiChangeRequestDeleteChangeRequestIdDeleteRaw(requestParameters: ApiChangeRequestDeleteChangeRequestIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiChangeRequestDeleteChangeRequestIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/DeleteChangeRequest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestDeleteChangeRequestIdDelete(requestParameters: ApiChangeRequestDeleteChangeRequestIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestDeleteChangeRequestIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiChangeRequestDeleteSpecialChangeRequestIdDeleteRaw(requestParameters: ApiChangeRequestDeleteSpecialChangeRequestIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiChangeRequestDeleteSpecialChangeRequestIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/DeleteSpecialChangeRequest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestDeleteSpecialChangeRequestIdDelete(requestParameters: ApiChangeRequestDeleteSpecialChangeRequestIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestDeleteSpecialChangeRequestIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDeleteRaw(requestParameters: ApiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError('sourceId','Required parameter requestParameters.sourceId was null or undefined when calling apiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/DeleteSpecialChangeRequestSourceId/{sourceId}`.replace(`{${"sourceId"}}`, encodeURIComponent(String(requestParameters.sourceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDelete(requestParameters: ApiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestDeleteSpecialChangeRequestSourceIdSourceIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiChangeRequestExportChangeRequestsResultsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/ExportChangeRequestsResults`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestExportChangeRequestsResultsPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestExportChangeRequestsResultsPostRaw(initOverrides);
    }

    /**
     */
    async apiChangeRequestGetAllSubjectsGetRaw(requestParameters: ApiChangeRequestGetAllSubjectsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SubjectPlan>>> {
        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetAllSubjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectPlanFromJSON));
    }

    /**
     */
    async apiChangeRequestGetAllSubjectsGet(requestParameters: ApiChangeRequestGetAllSubjectsGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SubjectPlan>> {
        const response = await this.apiChangeRequestGetAllSubjectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestGetChangeRequestResponsesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeRequestResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetChangeRequestResponses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeRequestResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiChangeRequestGetChangeRequestResponsesGet(initOverrides?: RequestInit): Promise<ChangeRequestResponseDTO> {
        const response = await this.apiChangeRequestGetChangeRequestResponsesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestGetChangeRequestsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeRequestsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetChangeRequests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeRequestsDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiChangeRequestGetChangeRequestsGet(initOverrides?: RequestInit): Promise<ChangeRequestsDTO> {
        const response = await this.apiChangeRequestGetChangeRequestsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestGetDestinationSubjectsGetRaw(requestParameters: ApiChangeRequestGetDestinationSubjectsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SubjectPlanDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.semester !== undefined) {
            queryParameters['Semester'] = requestParameters.semester;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.credits !== undefined) {
            queryParameters['Credits'] = requestParameters.credits;
        }

        if (requestParameters.series !== undefined) {
            queryParameters['Series'] = requestParameters.series;
        }

        if (requestParameters.specialization !== undefined) {
            queryParameters['Specialization'] = requestParameters.specialization;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['Year'] = requestParameters.year;
        }

        if (requestParameters.sourceSubjectName !== undefined) {
            queryParameters['SourceSubjectName'] = requestParameters.sourceSubjectName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetDestinationSubjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectPlanDTOFromJSON));
    }

    /**
     */
    async apiChangeRequestGetDestinationSubjectsGet(requestParameters: ApiChangeRequestGetDestinationSubjectsGetRequest = {}, initOverrides?: RequestInit): Promise<Array<SubjectPlanDTO>> {
        const response = await this.apiChangeRequestGetDestinationSubjectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestGetRemainingChangesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetRemainingChanges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiChangeRequestGetRemainingChangesGet(initOverrides?: RequestInit): Promise<number> {
        const response = await this.apiChangeRequestGetRemainingChangesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestGetSourceSubjectsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SubjectPlanDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetSourceSubjects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectPlanDTOFromJSON));
    }

    /**
     */
    async apiChangeRequestGetSourceSubjectsGet(initOverrides?: RequestInit): Promise<Array<SubjectPlanDTO>> {
        const response = await this.apiChangeRequestGetSourceSubjectsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestGetSpecialChangeRequestResponsesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SpecialChangeRequestResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/GetSpecialChangeRequestResponses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialChangeRequestResponseDTOFromJSON));
    }

    /**
     */
    async apiChangeRequestGetSpecialChangeRequestResponsesGet(initOverrides?: RequestInit): Promise<Array<SpecialChangeRequestResponseDTO>> {
        const response = await this.apiChangeRequestGetSpecialChangeRequestResponsesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestPostChangeRequestPostRaw(requestParameters: ApiChangeRequestPostChangeRequestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeRequestDTO>> {
        if (requestParameters.changeRequestDTO === null || requestParameters.changeRequestDTO === undefined) {
            throw new runtime.RequiredError('changeRequestDTO','Required parameter requestParameters.changeRequestDTO was null or undefined when calling apiChangeRequestPostChangeRequestPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/PostChangeRequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRequestDTOToJSON(requestParameters.changeRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeRequestDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiChangeRequestPostChangeRequestPost(requestParameters: ApiChangeRequestPostChangeRequestPostRequest, initOverrides?: RequestInit): Promise<ChangeRequestDTO> {
        const response = await this.apiChangeRequestPostChangeRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiChangeRequestPostSpecialChangeRequestPostRaw(requestParameters: ApiChangeRequestPostSpecialChangeRequestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.specialChangeRequestDTO === null || requestParameters.specialChangeRequestDTO === undefined) {
            throw new runtime.RequiredError('specialChangeRequestDTO','Required parameter requestParameters.specialChangeRequestDTO was null or undefined when calling apiChangeRequestPostSpecialChangeRequestPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/PostSpecialChangeRequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialChangeRequestDTOToJSON(requestParameters.specialChangeRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestPostSpecialChangeRequestPost(requestParameters: ApiChangeRequestPostSpecialChangeRequestPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestPostSpecialChangeRequestPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiChangeRequestRemoveFaultyOptionsPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/RemoveFaultyOptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiChangeRequestRemoveFaultyOptionsPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiChangeRequestRemoveFaultyOptionsPostRaw(initOverrides);
    }

    /**
     */
    async apiChangeRequestUpdateAvailableSpotsPutRaw(requestParameters: ApiChangeRequestUpdateAvailableSpotsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectPlanDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/ChangeRequest/UpdateAvailableSpots`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNumberOfSpotsDTOToJSON(requestParameters.updateNumberOfSpotsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectPlanDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiChangeRequestUpdateAvailableSpotsPut(requestParameters: ApiChangeRequestUpdateAvailableSpotsPutRequest = {}, initOverrides?: RequestInit): Promise<SubjectPlanDTO> {
        const response = await this.apiChangeRequestUpdateAvailableSpotsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
