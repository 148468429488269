import { AcademicUserApi, ImportOptionalsApi } from "../Api/apis";
import { StudentYearEnum } from "../Api/models";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthAcademicUserApiFactory = () => new AcademicUserApi(getAuthConfiguration());

const ImportOptionalsApiFactory = () => new ImportOptionalsApi(getAuthConfiguration());

export const getStudentById = (id: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetStudentByIdIdGet({ id });
}

export const getRolesForUser = () => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetRolesForUserGet();
}

export const updateUsers = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsUpdateUsersPost();
}

export const fetchAndImportUndergraduateStudents = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsFetchAndImportUndergraduateStudentsPost();
}

export const fetchAndImportMasterStudents = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsFetchAndImportMastersStudentsPost();
}