/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNumberOfSpotsMasterDTO
 */
export interface UpdateNumberOfSpotsMasterDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateNumberOfSpotsMasterDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateNumberOfSpotsMasterDTO
     */
    minimumSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNumberOfSpotsMasterDTO
     */
    maximumSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateNumberOfSpotsMasterDTO
     */
    enrolledStudents?: number;
}

export function UpdateNumberOfSpotsMasterDTOFromJSON(json: any): UpdateNumberOfSpotsMasterDTO {
    return UpdateNumberOfSpotsMasterDTOFromJSONTyped(json, false);
}

export function UpdateNumberOfSpotsMasterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNumberOfSpotsMasterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'minimumSpots': !exists(json, 'minimumSpots') ? undefined : json['minimumSpots'],
        'maximumSpots': !exists(json, 'maximumSpots') ? undefined : json['maximumSpots'],
        'enrolledStudents': !exists(json, 'enrolledStudents') ? undefined : json['enrolledStudents'],
    };
}

export function UpdateNumberOfSpotsMasterDTOToJSON(value?: UpdateNumberOfSpotsMasterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'minimumSpots': value.minimumSpots,
        'maximumSpots': value.maximumSpots,
        'enrolledStudents': value.enrolledStudents,
    };
}

