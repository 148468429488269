import Layout from '../../Containers/Layout';
import styles from './Home.module.scss';
import { useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import image1 from '../../Media/logoheader.svg';
import logoACS from '../../Media/acs-logo-ro.png';
import image2 from '../../Media/home.svg';
import image2ACS from '../../Media/undraw_election_day_w842.svg';
import CustomButton from '../../CustomComponents/CustomButton';
import { theme } from '../../theme';
import { NOTIFICATION_TYPES, openNotification } from '../Notifications/NotificationsUtils';
import { useTranslation } from 'react-i18next';
import {isUserLogged, useIsAdmin, useIsCompany, useIsMasterStudent, useIsProfessor, useIsStudent} from "../../utils/utilFunctions";
import { useQueryClient } from 'react-query';


const Home = () => {
  const { t } = useTranslation();
  const [boolInfo, setBoolInfo] = useState(false);
  const [studentBool, setStudentBool] = useState(false);
  const [adminBool, setAdminBool] = useState(false);
  const search = useLocation().search;
  const isStudent = useIsStudent();
  const isCompany = useIsCompany();
  const isAdmin = useIsAdmin();
  const isProfessor = useIsProfessor();
  const queryClient = useQueryClient();
  const isMasterStudent = useIsMasterStudent();

  const openExpire = () => openNotification(
    t('home.messages.sessionExpired'),
    t('home.messages.sessionExpiredMessage'),
    NOTIFICATION_TYPES.ERROR
  );

  useEffect(() => {
    if (!boolInfo) {
      const token = new URLSearchParams(search).get('token') as any;
      const expired = new URLSearchParams(search).get('expired') as any;

      if (expired) {
        queryClient.invalidateQueries("getRoles");
        openExpire();
      }

      //TO DO: complete fetch info for profile

      if (token) {
        localStorage.setItem('token', token);

        if (isStudent) {
          setStudentBool(true);
        }
        else if (isAdmin) {
          setAdminBool(true);
        }
      } else {
        const error = new URLSearchParams(search).get('error');
        if (error) {
          openNotification(
            t('home.messages.loginFailure'), 
            t('home.messages.noAccount'), 
            NOTIFICATION_TYPES.ERROR
          );
        }
      }
      setBoolInfo(true);
    }
  }, [search, boolInfo, t]);

  if (adminBool) {
    return (<Redirect to='/stagii' />);
  }
  else if (studentBool) {
    return (<Redirect to='/stagii' />);
  }
  else {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.container}>
              <div className={styles.fadeinimage}>
                <img alt={'img1'} src={logoACS} />
              </div>
            
            
           

            {isUserLogged() && isStudent && !isMasterStudent &&
                <CustomButton
                  backgroundcolor={theme.secondColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                >
                  <Link to='/alegere-specializare' >Selectare specializări</Link>
                </CustomButton>
            }

            {isUserLogged() && isStudent && isMasterStudent &&
                <CustomButton
                  backgroundcolor={theme.secondColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                >
                  <Link to='/schimbare-materii' >Cereri de schimbare</Link>
                </CustomButton>
            }

            {isUserLogged() && isStudent &&
                <CustomButton
                  backgroundcolor={theme.primaryColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                  margintop={"2%"}
                >
                  <Link to='/alegere-optionale' >Selectare opționale</Link>
                </CustomButton>
            }

            {isUserLogged() && !isStudent &&
                <CustomButton
                  backgroundcolor={theme.secondColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                >
                  <Link to='/configurare-specializari' >Configurare specializări</Link>
                </CustomButton>
            }
            {isUserLogged() && !isStudent &&
                <CustomButton
                  backgroundcolor={theme.primaryColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                  margintop={"2%"}
                >
                  <Link to='/configurare-optionale' >Configurare opționale</Link>
                </CustomButton>
            }

            {isUserLogged() && !isStudent &&
                <CustomButton
                  backgroundcolor={theme.secondColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                  margintop={"2%"}
                >
                  <Link to='/configurare-cereri-schimbare' >Configurare cereri</Link>
                </CustomButton>
            }


            {!isUserLogged()  ?
              <div>
                <CustomButton
                  backgroundcolor={theme.primaryColorACS}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                  margintop={"2%"}
                >
                  <Link to='/autentificare' >Autentificare</Link>
                </CustomButton>
              </div>
            : null}

          </div>
          <div>
            <img className={styles.svg} alt={'img2'} style={{marginRight: '200px'}} src={image2ACS} />
          </div>
          
        </div>
      </Layout >
    );
  }
}
export default Home;