/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleSubjectPlanDTO,
    SimpleSubjectPlanDTOFromJSON,
    SimpleSubjectPlanDTOFromJSONTyped,
    SimpleSubjectPlanDTOToJSON,
} from './SimpleSubjectPlanDTO';

/**
 * 
 * @export
 * @interface ChangeRequestDTO
 */
export interface ChangeRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDTO
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDTO
     */
    studentId?: string;
    /**
     * 
     * @type {SimpleSubjectPlanDTO}
     * @memberof ChangeRequestDTO
     */
    sourceSubjectPlan?: SimpleSubjectPlanDTO;
    /**
     * 
     * @type {Array<SimpleSubjectPlanDTO>}
     * @memberof ChangeRequestDTO
     */
    destinationSubjectPlans?: Array<SimpleSubjectPlanDTO> | null;
}

export function ChangeRequestDTOFromJSON(json: any): ChangeRequestDTO {
    return ChangeRequestDTOFromJSONTyped(json, false);
}

export function ChangeRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'sourceSubjectPlan': !exists(json, 'sourceSubjectPlan') ? undefined : SimpleSubjectPlanDTOFromJSON(json['sourceSubjectPlan']),
        'destinationSubjectPlans': !exists(json, 'destinationSubjectPlans') ? undefined : (json['destinationSubjectPlans'] === null ? null : (json['destinationSubjectPlans'] as Array<any>).map(SimpleSubjectPlanDTOFromJSON)),
    };
}

export function ChangeRequestDTOToJSON(value?: ChangeRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'studentId': value.studentId,
        'sourceSubjectPlan': SimpleSubjectPlanDTOToJSON(value.sourceSubjectPlan),
        'destinationSubjectPlans': value.destinationSubjectPlans === undefined ? undefined : (value.destinationSubjectPlans === null ? null : (value.destinationSubjectPlans as Array<any>).map(SimpleSubjectPlanDTOToJSON)),
    };
}

