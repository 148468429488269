/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureFlagsEnum,
    FeatureFlagsEnumFromJSON,
    FeatureFlagsEnumFromJSONTyped,
    FeatureFlagsEnumToJSON,
} from './FeatureFlagsEnum';

/**
 * 
 * @export
 * @interface UpdateFeatureFlagRequestDTO
 */
export interface UpdateFeatureFlagRequestDTO {
    /**
     * 
     * @type {FeatureFlagsEnum}
     * @memberof UpdateFeatureFlagRequestDTO
     */
    flag?: FeatureFlagsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeatureFlagRequestDTO
     */
    newValue?: boolean;
}

export function UpdateFeatureFlagRequestDTOFromJSON(json: any): UpdateFeatureFlagRequestDTO {
    return UpdateFeatureFlagRequestDTOFromJSONTyped(json, false);
}

export function UpdateFeatureFlagRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFeatureFlagRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'flag': !exists(json, 'flag') ? undefined : FeatureFlagsEnumFromJSON(json['flag']),
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
    };
}

export function UpdateFeatureFlagRequestDTOToJSON(value?: UpdateFeatureFlagRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'flag': FeatureFlagsEnumToJSON(value.flag),
        'newValue': value.newValue,
    };
}

