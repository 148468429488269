/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfigureOptionalResponseDTO,
    ConfigureOptionalResponseDTOFromJSON,
    ConfigureOptionalResponseDTOToJSON,
    ConfigureOptionalsDTO,
    ConfigureOptionalsDTOFromJSON,
    ConfigureOptionalsDTOToJSON,
    SemesterEnum,
    SemesterEnumFromJSON,
    SemesterEnumToJSON,
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumToJSON,
    Subject,
    SubjectFromJSON,
    SubjectToJSON,
    SubjectPlan,
    SubjectPlanFromJSON,
    SubjectPlanToJSON,
    UpdateNumberOfSpotsDTO,
    UpdateNumberOfSpotsDTOFromJSON,
    UpdateNumberOfSpotsDTOToJSON,
    UpdateNumberOfSpotsMasterDTO,
    UpdateNumberOfSpotsMasterDTOFromJSON,
    UpdateNumberOfSpotsMasterDTOToJSON,
    UserChoicesDTO,
    UserChoicesDTOFromJSON,
    UserChoicesDTOToJSON,
} from '../models';

export interface ApiSubjectConfigureUndergraduateOptionalsPutRequest {
    configureOptionalsDTO?: ConfigureOptionalsDTO;
}

export interface ApiSubjectGetAllGetRequest {
    semester?: SemesterEnum;
    year?: StudentYearEnum;
    domain?: string;
    specializationId?: string;
    name?: string;
}

export interface ApiSubjectGetAllMasterGetRequest {
    semester?: SemesterEnum;
    year?: StudentYearEnum;
    domain?: string;
    specializationId?: string;
    name?: string;
    program?: string;
}

export interface ApiSubjectGetChoicesBySemesterGetRequest {
    semester?: SemesterEnum;
}

export interface ApiSubjectPostPostRequest {
    semester?: SemesterEnum;
    userChoicesDTO?: UserChoicesDTO;
}

export interface ApiSubjectPostPutRequest {
    updateNumberOfSpotsDTO?: UpdateNumberOfSpotsDTO;
}

export interface ApiSubjectUpdateSpotsMasterBulkPutRequest {
    year?: StudentYearEnum;
    updateNumberOfSpotsMasterDTO?: UpdateNumberOfSpotsMasterDTO;
}

export interface ApiSubjectUpdateSpotsMasterPutRequest {
    updateNumberOfSpotsMasterDTO?: UpdateNumberOfSpotsMasterDTO;
}

/**
 * 
 */
export class SubjectApi extends runtime.BaseAPI {

    /**
     */
    async apiSubjectConfigureUndergraduateOptionalsPutRaw(requestParameters: ApiSubjectConfigureUndergraduateOptionalsPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConfigureOptionalResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/ConfigureUndergraduateOptionals`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigureOptionalsDTOToJSON(requestParameters.configureOptionalsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigureOptionalResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectConfigureUndergraduateOptionalsPut(requestParameters: ApiSubjectConfigureUndergraduateOptionalsPutRequest = {}, initOverrides?: RequestInit): Promise<ConfigureOptionalResponseDTO> {
        const response = await this.apiSubjectConfigureUndergraduateOptionalsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectFetchAllUndergraduateOptionalsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConfigureOptionalResponseDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/FetchAllUndergraduateOptionals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConfigureOptionalResponseDTOFromJSON));
    }

    /**
     */
    async apiSubjectFetchAllUndergraduateOptionalsGet(initOverrides?: RequestInit): Promise<Array<ConfigureOptionalResponseDTO>> {
        const response = await this.apiSubjectFetchAllUndergraduateOptionalsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetAllGetRaw(requestParameters: ApiSubjectGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Subject>>> {
        const queryParameters: any = {};

        if (requestParameters.semester !== undefined) {
            queryParameters['Semester'] = requestParameters.semester;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['Year'] = requestParameters.year;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.specializationId !== undefined) {
            queryParameters['SpecializationId'] = requestParameters.specializationId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectFromJSON));
    }

    /**
     */
    async apiSubjectGetAllGet(requestParameters: ApiSubjectGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Subject>> {
        const response = await this.apiSubjectGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetAllMasterGetRaw(requestParameters: ApiSubjectGetAllMasterGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Subject>>> {
        const queryParameters: any = {};

        if (requestParameters.semester !== undefined) {
            queryParameters['Semester'] = requestParameters.semester;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['Year'] = requestParameters.year;
        }

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.specializationId !== undefined) {
            queryParameters['SpecializationId'] = requestParameters.specializationId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.program !== undefined) {
            queryParameters['program'] = requestParameters.program;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetAllMaster`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectFromJSON));
    }

    /**
     */
    async apiSubjectGetAllMasterGet(requestParameters: ApiSubjectGetAllMasterGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Subject>> {
        const response = await this.apiSubjectGetAllMasterGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectGetChoicesBySemesterGetRaw(requestParameters: ApiSubjectGetChoicesBySemesterGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Subject>>> {
        const queryParameters: any = {};

        if (requestParameters.semester !== undefined) {
            queryParameters['semester'] = requestParameters.semester;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/GetChoicesBySemester`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectFromJSON));
    }

    /**
     */
    async apiSubjectGetChoicesBySemesterGet(requestParameters: ApiSubjectGetChoicesBySemesterGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Subject>> {
        const response = await this.apiSubjectGetChoicesBySemesterGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectPostPostRaw(requestParameters: ApiSubjectPostPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Subject>>> {
        const queryParameters: any = {};

        if (requestParameters.semester !== undefined) {
            queryParameters['semester'] = requestParameters.semester;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/Post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserChoicesDTOToJSON(requestParameters.userChoicesDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubjectFromJSON));
    }

    /**
     */
    async apiSubjectPostPost(requestParameters: ApiSubjectPostPostRequest = {}, initOverrides?: RequestInit): Promise<Array<Subject>> {
        const response = await this.apiSubjectPostPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectPostPutRaw(requestParameters: ApiSubjectPostPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Subject>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/Post`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNumberOfSpotsDTOToJSON(requestParameters.updateNumberOfSpotsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectPostPut(requestParameters: ApiSubjectPostPutRequest = {}, initOverrides?: RequestInit): Promise<Subject> {
        const response = await this.apiSubjectPostPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubjectUpdateSpotsMasterBulkPutRaw(requestParameters: ApiSubjectUpdateSpotsMasterBulkPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/UpdateSpotsMasterBulk`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNumberOfSpotsMasterDTOToJSON(requestParameters.updateNumberOfSpotsMasterDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSubjectUpdateSpotsMasterBulkPut(requestParameters: ApiSubjectUpdateSpotsMasterBulkPutRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiSubjectUpdateSpotsMasterBulkPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiSubjectUpdateSpotsMasterPutRaw(requestParameters: ApiSubjectUpdateSpotsMasterPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SubjectPlan>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Subject/UpdateSpotsMaster`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNumberOfSpotsMasterDTOToJSON(requestParameters.updateNumberOfSpotsMasterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectPlanFromJSON(jsonValue));
    }

    /**
     */
    async apiSubjectUpdateSpotsMasterPut(requestParameters: ApiSubjectUpdateSpotsMasterPutRequest = {}, initOverrides?: RequestInit): Promise<SubjectPlan> {
        const response = await this.apiSubjectUpdateSpotsMasterPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
