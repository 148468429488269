import { useState } from "react";
import styles from "./Optionals.module.scss";
import { Col, Row, Select, Spin, Table, Button } from "antd";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { SemesterEnum, SimulationType, StudentYearEnum } from "../../Api";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import { distribute, simulate, exportRepartitionResults } from "../../Requests/repartition-requests";
import { distributeChangeRequests, simulateChangeRequests } from "../../Requests/approve-change-requests";
import { ExportChangeRequestsResults } from "../../Requests/change-subject-requests";

const { Option } = Select;

const AdminRepartitionView = () => {
    const { t } = useTranslation();
    const [type, setType] = useState(SimulationType.Specialization);
    const [domain, setDomain] = useState('');
    const [year, setYear] = useState(StudentYearEnum.L2);
    const [semester, setSemester] = useState(SemesterEnum.FirstSemester);
    const [isSimulation, setIsSimulation] = useState(true);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);


    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('GetAllSubjectsFiltered') };

    const openErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Datele nu au putut fi aduse cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const openExportErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Datele nu au putut fi copiate catre studenti cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const openForbiddenErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Nu ai permisiuni pentru a efectua operatia!',
            NOTIFICATION_TYPES.ERROR
        );
    }


    const { data, isLoading, refetch,  } = useQuery(['GetAllSubjectsFiltered', isSimulation],
        () => {
            console.warn(isSimulation);
            
            if (type === SimulationType.ChangeRequest) {
                if (isSimulation) {
                    return simulateChangeRequests(year, domain);
                }
                else {
                    return distributeChangeRequests(year, domain);

                }
            }
            else {
                if (isSimulation) {
                    return simulate(domain, semester, type, year);
                }
                else {
                    return distribute(domain, semester, type, year);

                }
            }
        },
        {
            onError: (error) => {
                const errorWithResponse = error as {status?: number};
                if (errorWithResponse.status) {
                    const statusCode = errorWithResponse.status;
                    if (statusCode === 403) {
                        openForbiddenErrorNotification(error);
                    } else {
                        openErrorNotification(error); 
                    }
                } else {
                    openErrorNotification(error);
                }
            },
            refetchOnWindowFocus: false,
            enabled: false // disable this query from automatically running
        }
    );

    const exportResults = async () => {
        setIsUpdateLoading(true);
      
        try {
          if (type === SimulationType.ChangeRequest) {
            await ExportChangeRequestsResults();
          } else {
            await exportRepartitionResults();
          }
      
          setIsUpdateLoading(false);
        } catch (error) {
            console.error('An error occurred:', error);
            setIsUpdateLoading(false);
            const errorWithResponse = error as {status?: number};
            if (errorWithResponse.status) {
                const statusCode = errorWithResponse.status;
                if (statusCode === 403) {
                    openForbiddenErrorNotification(error);
                } else {
                    openExportErrorNotification(error); 
                }
            }
        }
      };
    

    const columns = [
        {
            title: 'Nume',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Număr locuri configurate',
            dataIndex: 'numberOfSpots',
            key: 'numberOfSpots',
        },
       {
            title: 'Număr locuri rămase',
            dataIndex: 'remainingSpots',
            key: 'remainingSpots',
        },
        {
            title: 'Număr prima opțiune',
            dataIndex: 'firstChoiceNumber',
            key: 'firstChoiceNumber'
        }
    ];

    const columnsChangeRequests = [
        {
            title: 'Nume',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Număr studenti inrolati',
            dataIndex: 'enrolledStudents',
            key: 'enrolledStudents',
        },
        {
            title: 'Număr locuri configurate',
            dataIndex: 'numberOfSpots',
            key: 'numberOfSpots',
        },
        {
            title: 'Număr locuri rămase',
            dataIndex: 'remainingSpots',
            key: 'remainingSpots',
        },
        {
            title: 'Număr plecări aprobate',
            dataIndex: 'numberOfStudentsLeaving',
            key: 'numberOfStudentsLeaving',
        },
        {
            title: 'Număr sosiri aprobate',
            dataIndex: 'numberOfStudentsAccepted',
            key: 'numberOfStudentsAccepted',
        },
        {
            title: 'Număr cereri plecare',
            dataIndex: 'numberOfRequestsToChangeSubject',
            key: 'numberOfRequestsToChangeSubject',
        },
        {
            title: 'Număr cereri venire',
            dataIndex: 'numberOfRequestsToTakeSubject',
            key: 'numberOfRequestsToTakeSubject',
        }
    ];
    const handleChangeOperationType = (value: boolean) => {
        console.log(`selected ${value}`);
        setIsSimulation(value);
    };

    const handleChangeType = (value: SimulationType) => {
        console.log(`selected ${value}`);
        setType(value);
    };

    const handleChangeDomain = (value: string) => {
        console.log(`selected ${value}`);
        setDomain(value);

    };

    const handleChangeYear = (value: StudentYearEnum) => {
        console.log(`selected ${value}`);
        setYear(value);
    };

    const handleChangeSemester = (value: SemesterEnum) => {
        console.log(`selected ${value}`);
        setSemester(value);
    };

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Row>
                <Col span={5}>
                    <Row>
                        <Select defaultValue={true} style={{ width: 300 }} onChange={handleChangeOperationType}>
                            <Option value={true}>Simulare</Option>
                            <Option value={false}>Repartizare</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Select placeholder="Alegere tip repartizare" style={{ width: 300 }} onChange={handleChangeType}>
                            <Option value={SimulationType.Specialization}>Specializari</Option>
                            <Option value={SimulationType.Optional}>Optionale</Option>
                            <Option value={SimulationType.ChangeRequest}>Schimbare materii</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Select placeholder="Domeniu" style={{ width: 300 }} onChange={handleChangeDomain}>
                            <Option value="CTI">CTI</Option>
                            <Option value="IS">IS</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Select placeholder="An" style={{ width: 300 }} onChange={handleChangeYear}>
                            <Option value={StudentYearEnum.L2}>Licenta II</Option>
                            <Option value={StudentYearEnum.L3}>Licenta III</Option>
                            <Option value={StudentYearEnum.L4}>Licenta IV</Option>
                            <Option value={StudentYearEnum.M1}>Masterat</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Select placeholder="Grup Materii" style={{ width: 300 }} onChange={handleChangeSemester}>
                            <Option value={SemesterEnum.FirstSemester}>Semestrul 1 - grup 1</Option>
                            <Option value={SemesterEnum.FirstSemesterSecondSubject}>Semestrul 1 - grup 2</Option>
                            <Option value={SemesterEnum.SecondSemesterFirstSubject}>Semestrul 2 - grup 1</Option>
                            <Option value={SemesterEnum.SecondSemesterSecondSubject}>Semestrul 2 - grup 2</Option>
                            <Option value={SemesterEnum.SecondSemesterThirdSubject}>Semestrul 2 - grup 3</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Button onClick={() => { refetch(); }}>
                            Rulare Algoritm
                        </Button>
                        {isLoading && <Spin style={{marginTop: '15px', marginLeft: '10px'}} />}
                        <Button onClick={() => { exportResults(); }}>
                            {type === SimulationType.ChangeRequest 
                            ? 'Exporta rezultate cereri schimbare'
                            : 'Exporta rezultate optionale' }
                        </Button>
                        {isUpdateLoading && <Spin style={{marginTop: '15px', marginLeft: '10px'}} />}
                    </Row>
                </Col>
                <Col span={19}>
                <div
                style={{
                    height: "calc(100vh - 180px)",
                    overflow: "auto"
                }}
                >
                    <Table
                        className={styles.usersTable}
                        dataSource={data ?? new Array<any>()}
                        columns={(type === SimulationType.ChangeRequest) ? columnsChangeRequests : columns}
                        pagination={false}
                        rowKey={record => record.id ?? ''}
                        loading={isLoading}
                        sticky={true}
                    />
                    </div>
                </Col>

            </Row>
        </Layout>
    </div>
}

export default AdminRepartitionView;