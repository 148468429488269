import { Table, Switch} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useDebounce } from "usehooks-ts";
import { FeatureFlags, FeatureFlagsEnum } from "../../Api";
import Layout from "../../Containers/Layout";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from '../../theme';
import { getFeatureFlagsValues, updateFeatureFlagValue } from "../../Requests/feature-flags-requests";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";
import { check } from "prettier";


const AdminFeatureFlagsView = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string>('');
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('GetAllFeatureFlags') };

    const openErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Datele nu au putut fi aduse cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['GetAllFeatureFlags'],
        () => {
            return getFeatureFlagsValues();
        },
        {
            onError: openErrorNotification,
        }
    );

    const toggleFeatureFlag = async (flagName: FeatureFlagsEnum, currentValue: boolean) => {
        setIsUpdateLoading(true);
        await updateFeatureFlagValue(flagName, !currentValue)
            .then(() => {
                invalidateQuery();
                setIsUpdateLoading(false);
            });
      };

    const columns = [
        {
            title: 'Nume flag',
            dataIndex: 'featureFlag',
            key: 'featureFlag',
        },
        {
            title: 'Descriere',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Activ',
            dataIndex: 'value',
            render: (_: any, record: FeatureFlags) => {
                return (<span>
                    <Switch loading={isUpdateLoading} checked={record.value!} onChange={() => {toggleFeatureFlag(record.featureFlag!, record.value!)}}/>
                </span>)
            }
            
        },
    ];

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Table
                style = {{marginTop: "3%"}}
                className={styles.usersTable}
                dataSource={data ?? new Array<FeatureFlags>()}
                columns={columns}
                pagination={false}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
            />
        </Layout>
    </div>
}

export default AdminFeatureFlagsView;