import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Table, Tooltip } from "antd";
import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useDebounce } from "usehooks-ts";
import { Subject } from "../../Api";
import Layout from "../../Containers/Layout";
import { AdminPostSubject, GetAllSubjectsFiltered } from "../../Requests/subject-requests";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";


const AdminOptionals = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTermDomain, setSearchTermDomain] = useState('');

    const [value, setValue] = useState<string>('');
    const [recordId, setRecordId] = useState<string>('');
    const debouncedValue = useDebounce<string>(value, 500);

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('GetAllSubjectsFiltered') };

    const openSpecializationErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchSpecializationsError'),
            NOTIFICATION_TYPES.ERROR
        );
    }
  
    const openForbiddenErrorNotification = (_error: any) => {
          openNotification(
              t('optionals.error'),
              'Nu ai permisiuni pentru a efectua operatia!',
              NOTIFICATION_TYPES.ERROR
          );
      }

    const { data, isLoading } = useQuery(['GetAllSubjectsFiltered', searchTermName, searchTermDomain],
        () => {
            return GetAllSubjectsFiltered(searchTermName, searchTermDomain);
        },
        {
            onError: openSpecializationErrorNotification,
        }
    );

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: 200 }} />
    );

    const FilterByDomainInput = (
        <Search placeholder='Domeniu' allowClear onSearch={setSearchTermDomain} style={{ width: 200 }} />
    );

    const handleShow = (id: string) => {
        setRecordId(id);
    }

    const handleClose = () => {
        setRecordId('');
    }

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            render: (text: string, record: Subject, index: number) => {
                return !!record.specialization
                    ? record.name + " (" + record.specialization?.name?.substring(0, 2) + ")"
                    : record.name
            }
        },
        {
            title: FilterByDomainInput,
            dataIndex: 'domain',
            key: 'domain',
        },
        {
            title: 'An',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Număr locuri',
            dataIndex: 'availableSpots',
            key: 'availableSpots'
        },
        {
            title: 'Editare nr. locuri',
            render: (text: string, record: Subject, index: number) => {
                return recordId === record.id ?
                    <Row>
                        <Col span={18}>
                            <Input onChange={onInputChange(record)} />
                        </Col>
                        <Col span={6}>
                            <Tooltip title='Acceptă modificările'>
                                <FontAwesomeIcon icon={solid('check')} onClick={handleClose} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }} />
                            </Tooltip>
                        </Col>
                    </Row>
                    :
                    <Col span={24}>
                        <Tooltip title='Editare'>
                            <FontAwesomeIcon icon={solid('pen-to-square')} onClick={() => handleShow(record.id!)} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }} />
                        </Tooltip>
                    </Col>
            }
        }
    ];

    const onInputChange = (record: Subject) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        setRecordId(record.id!);
    };

    useEffect(() => {
        const updateSubject = async () => {
            try {
                await AdminPostSubject({ updateNumberOfSpotsDTO: { id: recordId, availableSpots: parseInt(value) } });
                invalidateQuery();
            } catch (errInfo) {
                console.log('Update Failed:', errInfo);
                const errorWithResponse = errInfo as {status?: number};
                if (errorWithResponse.status) {
                    const statusCode = errorWithResponse.status;
                    if (statusCode === 403) {
                        openForbiddenErrorNotification(errInfo);
                    }
                }
            }
        };
        updateSubject();
    }, [debouncedValue])

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Table
                className={styles.usersTable}
                dataSource={data ?? new Array<Subject>()}
                columns={columns}
                pagination={false}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
            />
        </Layout>
    </div>
}

export default AdminOptionals;