/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FeatureFlags,
    FeatureFlagsFromJSON,
    FeatureFlagsToJSON,
    UpdateFeatureFlagRequestDTO,
    UpdateFeatureFlagRequestDTOFromJSON,
    UpdateFeatureFlagRequestDTOToJSON,
} from '../models';

export interface ApiFeatureFlagsUpdateFeatureFlagValuePutRequest {
    updateFeatureFlagRequestDTO?: UpdateFeatureFlagRequestDTO;
}

/**
 * 
 */
export class FeatureFlagsApi extends runtime.BaseAPI {

    /**
     */
    async apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureFlags>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeatureFlags/GetAddChangeRequestsEnabledFlagValue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagsFromJSON(jsonValue));
    }

    /**
     */
    async apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGet(initOverrides?: RequestInit): Promise<FeatureFlags> {
        const response = await this.apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeatureFlagsGetFeatureFlagsValuesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FeatureFlags>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeatureFlags/GetFeatureFlagsValues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeatureFlagsFromJSON));
    }

    /**
     */
    async apiFeatureFlagsGetFeatureFlagsValuesGet(initOverrides?: RequestInit): Promise<Array<FeatureFlags>> {
        const response = await this.apiFeatureFlagsGetFeatureFlagsValuesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureFlags>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeatureFlags/GetOptionalsChoosingEnabledFlagValue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagsFromJSON(jsonValue));
    }

    /**
     */
    async apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGet(initOverrides?: RequestInit): Promise<FeatureFlags> {
        const response = await this.apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureFlags>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeatureFlags/GetViewResultsChangeRequestsEnabledFlagValue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagsFromJSON(jsonValue));
    }

    /**
     */
    async apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGet(initOverrides?: RequestInit): Promise<FeatureFlags> {
        const response = await this.apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeatureFlags>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeatureFlags/GetViewResultsOptionalsEnabledFlagValue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagsFromJSON(jsonValue));
    }

    /**
     */
    async apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGet(initOverrides?: RequestInit): Promise<FeatureFlags> {
        const response = await this.apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeatureFlagsUpdateFeatureFlagValuePutRaw(requestParameters: ApiFeatureFlagsUpdateFeatureFlagValuePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FeatureFlags/UpdateFeatureFlagValue`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeatureFlagRequestDTOToJSON(requestParameters.updateFeatureFlagRequestDTO),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFeatureFlagsUpdateFeatureFlagValuePut(requestParameters: ApiFeatureFlagsUpdateFeatureFlagValuePutRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.apiFeatureFlagsUpdateFeatureFlagValuePutRaw(requestParameters, initOverrides);
    }

}
