/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeRequest,
    ChangeRequestFromJSON,
    ChangeRequestFromJSONTyped,
    ChangeRequestToJSON,
} from './ChangeRequest';
import {
    ChangeRequestsResults,
    ChangeRequestsResultsFromJSON,
    ChangeRequestsResultsFromJSONTyped,
    ChangeRequestsResultsToJSON,
} from './ChangeRequestsResults';
import {
    ShortSemesterEnum,
    ShortSemesterEnumFromJSON,
    ShortSemesterEnumFromJSONTyped,
    ShortSemesterEnumToJSON,
} from './ShortSemesterEnum';
import {
    SpecialChangeRequest,
    SpecialChangeRequestFromJSON,
    SpecialChangeRequestFromJSONTyped,
    SpecialChangeRequestToJSON,
} from './SpecialChangeRequest';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';
import {
    SubjectTypeEnum,
    SubjectTypeEnumFromJSON,
    SubjectTypeEnumFromJSONTyped,
    SubjectTypeEnumToJSON,
} from './SubjectTypeEnum';

/**
 * 
 * @export
 * @interface SubjectPlan
 */
export interface SubjectPlan {
    /**
     * 
     * @type {string}
     * @memberof SubjectPlan
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SubjectPlan
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SubjectPlan
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SubjectPlan
     */
    name?: string | null;
    /**
     * 
     * @type {SubjectTypeEnum}
     * @memberof SubjectPlan
     */
    type?: SubjectTypeEnum;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof SubjectPlan
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {ShortSemesterEnum}
     * @memberof SubjectPlan
     */
    semester?: ShortSemesterEnum;
    /**
     * 
     * @type {string}
     * @memberof SubjectPlan
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectPlan
     */
    series?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    initialAvailableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    availableSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    credits?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    subjectId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    availableSpotsFirstYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    availableSpotsSecondYear?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    minimumSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    maximumSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    enrolledStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof SubjectPlan
     */
    initialEnrolledStudents?: number;
    /**
     * 
     * @type {Array<ChangeRequest>}
     * @memberof SubjectPlan
     */
    changeRequests?: Array<ChangeRequest> | null;
    /**
     * 
     * @type {Array<ChangeRequestsResults>}
     * @memberof SubjectPlan
     */
    changeRequestsResults?: Array<ChangeRequestsResults> | null;
    /**
     * 
     * @type {Array<ChangeRequest>}
     * @memberof SubjectPlan
     */
    changeRequestsAsAcceptedDestination?: Array<ChangeRequest> | null;
    /**
     * 
     * @type {Array<ChangeRequestsResults>}
     * @memberof SubjectPlan
     */
    changeRequestsResultsAsAcceptedDestination?: Array<ChangeRequestsResults> | null;
    /**
     * 
     * @type {Array<SpecialChangeRequest>}
     * @memberof SubjectPlan
     */
    specialChangeRequests?: Array<SpecialChangeRequest> | null;
}

export function SubjectPlanFromJSON(json: any): SubjectPlan {
    return SubjectPlanFromJSONTyped(json, false);
}

export function SubjectPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : SubjectTypeEnumFromJSON(json['type']),
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'semester': !exists(json, 'semester') ? undefined : ShortSemesterEnumFromJSON(json['semester']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'initialAvailableSpots': !exists(json, 'initialAvailableSpots') ? undefined : json['initialAvailableSpots'],
        'availableSpots': !exists(json, 'availableSpots') ? undefined : json['availableSpots'],
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'availableSpotsFirstYear': !exists(json, 'availableSpotsFirstYear') ? undefined : json['availableSpotsFirstYear'],
        'availableSpotsSecondYear': !exists(json, 'availableSpotsSecondYear') ? undefined : json['availableSpotsSecondYear'],
        'minimumSpots': !exists(json, 'minimumSpots') ? undefined : json['minimumSpots'],
        'maximumSpots': !exists(json, 'maximumSpots') ? undefined : json['maximumSpots'],
        'enrolledStudents': !exists(json, 'enrolledStudents') ? undefined : json['enrolledStudents'],
        'initialEnrolledStudents': !exists(json, 'initialEnrolledStudents') ? undefined : json['initialEnrolledStudents'],
        'changeRequests': !exists(json, 'changeRequests') ? undefined : (json['changeRequests'] === null ? null : (json['changeRequests'] as Array<any>).map(ChangeRequestFromJSON)),
        'changeRequestsResults': !exists(json, 'changeRequestsResults') ? undefined : (json['changeRequestsResults'] === null ? null : (json['changeRequestsResults'] as Array<any>).map(ChangeRequestsResultsFromJSON)),
        'changeRequestsAsAcceptedDestination': !exists(json, 'changeRequestsAsAcceptedDestination') ? undefined : (json['changeRequestsAsAcceptedDestination'] === null ? null : (json['changeRequestsAsAcceptedDestination'] as Array<any>).map(ChangeRequestFromJSON)),
        'changeRequestsResultsAsAcceptedDestination': !exists(json, 'changeRequestsResultsAsAcceptedDestination') ? undefined : (json['changeRequestsResultsAsAcceptedDestination'] === null ? null : (json['changeRequestsResultsAsAcceptedDestination'] as Array<any>).map(ChangeRequestsResultsFromJSON)),
        'specialChangeRequests': !exists(json, 'specialChangeRequests') ? undefined : (json['specialChangeRequests'] === null ? null : (json['specialChangeRequests'] as Array<any>).map(SpecialChangeRequestFromJSON)),
    };
}

export function SubjectPlanToJSON(value?: SubjectPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'type': SubjectTypeEnumToJSON(value.type),
        'year': StudentYearEnumToJSON(value.year),
        'semester': ShortSemesterEnumToJSON(value.semester),
        'domain': value.domain,
        'series': value.series,
        'initialAvailableSpots': value.initialAvailableSpots,
        'availableSpots': value.availableSpots,
        'credits': value.credits,
        'subjectId': value.subjectId,
        'availableSpotsFirstYear': value.availableSpotsFirstYear,
        'availableSpotsSecondYear': value.availableSpotsSecondYear,
        'minimumSpots': value.minimumSpots,
        'maximumSpots': value.maximumSpots,
        'enrolledStudents': value.enrolledStudents,
        'initialEnrolledStudents': value.initialEnrolledStudents,
        'changeRequests': value.changeRequests === undefined ? undefined : (value.changeRequests === null ? null : (value.changeRequests as Array<any>).map(ChangeRequestToJSON)),
        'changeRequestsResults': value.changeRequestsResults === undefined ? undefined : (value.changeRequestsResults === null ? null : (value.changeRequestsResults as Array<any>).map(ChangeRequestsResultsToJSON)),
        'changeRequestsAsAcceptedDestination': value.changeRequestsAsAcceptedDestination === undefined ? undefined : (value.changeRequestsAsAcceptedDestination === null ? null : (value.changeRequestsAsAcceptedDestination as Array<any>).map(ChangeRequestToJSON)),
        'changeRequestsResultsAsAcceptedDestination': value.changeRequestsResultsAsAcceptedDestination === undefined ? undefined : (value.changeRequestsResultsAsAcceptedDestination === null ? null : (value.changeRequestsResultsAsAcceptedDestination as Array<any>).map(ChangeRequestsResultsToJSON)),
        'specialChangeRequests': value.specialChangeRequests === undefined ? undefined : (value.specialChangeRequests === null ? null : (value.specialChangeRequests as Array<any>).map(SpecialChangeRequestToJSON)),
    };
}

