import Layout from "../../Containers/Layout";
import { useQuery } from "react-query";
import { useState } from "react";
import { getOptionalsRepartition, getSpecializationRepartition, getStudentGrade } from "../../Requests/repartition-requests";
import { SemesterEnum } from "../../Api";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { useTranslation } from "react-i18next";
import CustomList from "./CustomList";
import { ChooseAction } from "../../utils/constants";
import { useIsMasterStudent } from "../../utils/utilFunctions";


const RepartitionResult = () => {
    const { t } = useTranslation();
    const isMasterStudent = useIsMasterStudent();
    const [available, setIsAvailable] = useState(true);
    const openRepartitionErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.repartitionErrorFetched'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data } = useQuery(['getOptionalsRepartition2Sem2'],
        () => {
            return getOptionalsRepartition(SemesterEnum.SecondSemesterSecondSubject);
        }, {
        onError: (error) => {
            const errorWithResponse = error as {status?: number};
            if (errorWithResponse.status) {
                const statusCode = errorWithResponse.status;
                if (statusCode === 403) {
                    setIsAvailable(false);
                } else {
                    openRepartitionErrorNotification(error);
                }
            } else {
                openRepartitionErrorNotification(error);
            }
        },
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: () => {
            setIsAvailable(true);
        }
    });

    const { data: grade } = useQuery(['getStudentGrade'],
        () => {
            return getStudentGrade();
        }, {
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
    });

    return available ? (
        <Layout>
            <h2>Rezultatele în urma repartizării</h2>
            <h3>Media considerată pentru repartizare: {grade}</h3>
            {!isMasterStudent && <CustomList
                data={null}
                handleSave={null}
                choices={getSpecializationRepartition}
                type={ChooseAction.SpecializationRepartition}
                semester={null}
                title={'Specializare:'}
            />}
            <CustomList
                data={null}
                handleSave={null}
                choices={getOptionalsRepartition}
                type={ChooseAction.SubjectRepartition}
                semester={SemesterEnum.FirstSemester}
                title={data?.acceptedOptionals && data.acceptedOptionals?.length > 0 ? 'Opționale semestrul 1 - opțional 1:' : 'Opționale semestrul 1'}
            />
            <CustomList
                data={null}
                handleSave={null}
                choices={getOptionalsRepartition}
                type={ChooseAction.SubjectRepartition}
                semester={SemesterEnum.FirstSemesterSecondSubject}
                title={'Opționale semestrul 1 - opțional 2:'}
            />
            <CustomList
                data={null}
                handleSave={null}
                choices={getOptionalsRepartition}
                type={ChooseAction.SubjectRepartition}
                semester={SemesterEnum.SecondSemesterFirstSubject}
                title={data?.acceptedOptionals && data.acceptedOptionals?.length > 0 ? 'Opționale semestrul 2 - opțional 1:' : 'Opționale semestrul 2'}
            />
            <CustomList
                data={null}
                handleSave={null}
                choices={getOptionalsRepartition}
                type={ChooseAction.SubjectRepartition}
                semester={SemesterEnum.SecondSemesterSecondSubject}
                title={'Opționale semestrul 2 - opțional 2:'}
            />
            <CustomList
                data={null}
                handleSave={null}
                choices={getOptionalsRepartition}
                type={ChooseAction.SubjectRepartition}
                semester={SemesterEnum.SecondSemesterThirdSubject}
                title={'Opționale semestrul 2 - opțional 3:'}
            />
        </Layout>
    ) : ( 
        <Layout>
            <h2>{t('optionals.optionalsResultsNotEnabled')}</h2>
        </Layout>
    )
}

export default RepartitionResult;