/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Specialization,
    SpecializationFromJSON,
    SpecializationToJSON,
    UpdateNumberOfSpotsDTO,
    UpdateNumberOfSpotsDTOFromJSON,
    UpdateNumberOfSpotsDTOToJSON,
    UserChoicesDTO,
    UserChoicesDTOFromJSON,
    UserChoicesDTOToJSON,
} from '../models';

export interface ApiSpecializationGetAllGetRequest {
    domain?: string;
    name?: string;
}

export interface ApiSpecializationPostPostRequest {
    userChoicesDTO?: UserChoicesDTO;
}

export interface ApiSpecializationPostPutRequest {
    updateNumberOfSpotsDTO?: UpdateNumberOfSpotsDTO;
}

/**
 * 
 */
export class SpecializationApi extends runtime.BaseAPI {

    /**
     */
    async apiSpecializationGetAllGetRaw(requestParameters: ApiSpecializationGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Specialization>>> {
        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Specialization/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecializationFromJSON));
    }

    /**
     */
    async apiSpecializationGetAllGet(requestParameters: ApiSpecializationGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Specialization>> {
        const response = await this.apiSpecializationGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSpecializationGetChoicesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Specialization>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Specialization/GetChoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecializationFromJSON));
    }

    /**
     */
    async apiSpecializationGetChoicesGet(initOverrides?: RequestInit): Promise<Array<Specialization>> {
        const response = await this.apiSpecializationGetChoicesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSpecializationPostPostRaw(requestParameters: ApiSpecializationPostPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Specialization>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Specialization/Post`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserChoicesDTOToJSON(requestParameters.userChoicesDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecializationFromJSON));
    }

    /**
     */
    async apiSpecializationPostPost(requestParameters: ApiSpecializationPostPostRequest = {}, initOverrides?: RequestInit): Promise<Array<Specialization>> {
        const response = await this.apiSpecializationPostPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSpecializationPostPutRaw(requestParameters: ApiSpecializationPostPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Specialization>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Specialization/Post`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNumberOfSpotsDTOToJSON(requestParameters.updateNumberOfSpotsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecializationFromJSON(jsonValue));
    }

    /**
     */
    async apiSpecializationPostPut(requestParameters: ApiSpecializationPostPutRequest = {}, initOverrides?: RequestInit): Promise<Specialization> {
        const response = await this.apiSpecializationPostPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
