import { useFeatureFlag } from "configcat-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../../theme";
import {
    getUserId,
    isUserLogged,
    useIsMasterStudent,
    useIsProfessor,
    useIsStudent
} from '../../utils/utilFunctions';
import { useQuery } from "react-query";
import { 
    getViewResultsOptionalsEnabledFlagValue, 
    getViewResultsChangeRequestsEnabledFlagValue,
    getAddChangeRequestsEnabledFlagValue,
    getOptionalsChoosingEnabledFlagValue
} from "../../Requests/feature-flags-requests";
import { FeatureFlagsEnum } from "../../Api/models";
import { NOTIFICATION_TYPES, openNotification } from "../../Components/Notifications/NotificationsUtils";

const OptionalsHeadersLinks = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isStudent = useIsStudent();
    const isProfessor = useIsProfessor();
    const isMasterStudent = useIsMasterStudent();
    type FeatureFlagDictionary = Record<FeatureFlagsEnum, boolean>;

    const openErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Datele nu au putut fi aduse cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data: optionalsChoosingEnabled } = useQuery(['GetOptionalsChoosingEnabled'],
        () => {
            return getOptionalsChoosingEnabledFlagValue();
        }
    );

    const { data: changeRequestsEnabled} = useQuery(['GetAddChangeRequestsEnabled'],
        () => {
            return getAddChangeRequestsEnabledFlagValue();
        }
    );

    const { data: viewChangeRequestsResultsEnabled} = useQuery(['GetViewChangeRequestsResultsEnabled'],
        () => {
            return getViewResultsChangeRequestsEnabledFlagValue();
        }
    );

    const { data: viewOptionalsResultsEnabled} = useQuery(['GetViewOptionalsResultsEnabled'],
        () => {
            return getViewResultsOptionalsEnabledFlagValue();
        }
    );
    
    return (
        <>
            {isUserLogged() &&
                <Link
                    to='/'
                    style={{
                        fontWeight: location.pathname === "/" ? "bold" : "normal",
                        color: location.pathname === "/" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    {t('header.home')}
                </Link>
            }
            {isUserLogged() && isStudent && optionalsChoosingEnabled?.value ?
                (<Link
                    to="/alegere-specializare"
                    style={{
                        fontWeight: location.pathname === "/alegere-specializare" ? "bold" : "normal",
                        color: location.pathname === "/alegere-specializare" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    {t('optionals.chooseSpecializations')}
                </Link>)
                : null}

            {isUserLogged() && isStudent && optionalsChoosingEnabled?.value ?
                (<Link
                    to="/alegere-optionale"
                    style={{
                        fontWeight: location.pathname === "/alegere-optionale" ? "bold" : "normal",
                        color: location.pathname === "/alegere-optionale" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    {t('optionals.chooseOptionals')}
                </Link>)
                : null}

            {isUserLogged() && isStudent && viewOptionalsResultsEnabled?.value ?
                (<Link
                    to="/rezultate-repartizare"
                    style={{
                        fontWeight: location.pathname === "/rezultate-repartizare" ? "bold" : "normal",
                        color: location.pathname === "/rezultate-repartizare" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Rezultate repartizare
                </Link>)
                : null}

            {isUserLogged() && isStudent && changeRequestsEnabled?.value ?
                (<Link
                    to="/schimbare-materii"
                    style={{
                        fontWeight: location.pathname === "/schimbare-materii" ? "bold" : "normal",
                        color: location.pathname === "/schimbare-materii" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Schimbare materii
                </Link>)
                : null}

            {isUserLogged() && isStudent && viewChangeRequestsResultsEnabled?.value ?
                (<Link
                    to="/rezultate-cereri"
                    style={{
                        fontWeight: location.pathname === "/rezultate-cereri" ? "bold" : "normal",
                        color: location.pathname === "/rezultate-cereri" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Rezultate cereri
                </Link>)
                : null}

            {isUserLogged() && isProfessor &&
                (<Link
                    to="/configurare-optionale"
                    style={{
                        fontWeight: location.pathname === "/configurare-optionale" ? "bold" : "normal",
                        color: location.pathname === "/configurare-optionale" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Configurare opționale
                </Link>)
            }

            {isUserLogged() && isProfessor &&
                (<Link
                    to="/configurare-specializari"
                    style={{
                        fontWeight: location.pathname === "/configurare-specializari" ? "bold" : "normal",
                        color: location.pathname === "/configurare-specializari" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                    >
                    Configurare specializări
                </Link>)
            }

            {isUserLogged() && isProfessor &&
                (<Link
                    to="/configurare-cereri-schimbare"
                    style={{
                        fontWeight: location.pathname === "/configurare-cereri-schimbare" ? "bold" : "normal",
                        color: location.pathname === "/configurare-cereri-schimbare" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Configurare cereri
                </Link>)
            }

            {isUserLogged() && isProfessor &&
                (<Link
                    to="/configurare-master"
                    style={{
                        fontWeight: location.pathname === "/configurare-master" ? "bold" : "normal",
                        color: location.pathname === "/configurare-master" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Configurare master
                </Link>)
            }

            {isUserLogged() && isProfessor &&
                (<Link
                    to="/repartizare"
                    style={{
                        fontWeight: location.pathname === "/repartizare" ? "bold" : "normal",
                        color: location.pathname === "/repartizare" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Simulări și Repartizări
                </Link>)
            }
            {isUserLogged() && isProfessor &&
                (<Link
                    to="/rapoarte"
                    style={{
                        fontWeight: location.pathname === "/rapoarte" ? "bold" : "normal",
                        color: location.pathname === "/rapoarte" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Rapoarte
                </Link>)
            }
            {isUserLogged() && isProfessor &&
                (<Link
                    to="/flags"
                    style={{
                        fontWeight: location.pathname === "/flags" ? "bold" : "normal",
                        color: location.pathname === "/flags" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Flaguri
                </Link>)
            }
            {isUserLogged() && isProfessor &&
                (<Link
                    to="/import-date"
                    style={{
                        fontWeight: location.pathname === "/import-date" ? "bold" : "normal",
                        color: location.pathname === "/import-date" ? theme.primaryColorACS : theme.black,
                        fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                        padding: window.innerWidth > 1215 ? "0" : "5px",
                    }}
                >
                    Import date
                </Link>)
            }
        </>
    );

};

export default OptionalsHeadersLinks;