import { Table, Switch, Card, Tabs, Typography, Popconfirm, Form, Select, Button} from "antd";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useDebounce } from "usehooks-ts";
import { ConfigureOptionalResponseDTO, FeatureFlags, FeatureFlagsEnum, SemesterEnum, SpecializationEnum, StudentYearEnum } from "../../Api";
import Layout from "../../Containers/Layout";
import { updateUsers, fetchAndImportMasterStudents, fetchAndImportUndergraduateStudents } from "../../Requests/academic-user-requests";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";
import { check } from "prettier";
import { deleteOldCurriculum, deleteOldOptionals, fetchAndImportUndergraduateCurriculum, fetchAndImportMastersCurriculum, updateFaculties, fetchAllUndergraduateOptionals, updateNumberOfChangeRequestsAfterRepartition, configureUndergraduateOptionals } from "../../Requests/subject-requests";


const AdminDataImportView = () => {
    const { t } = useTranslation();
    const { TabPane } = Tabs;

    const queryClient = useQueryClient();
    const [editingKey, setEditingKey] = useState('');
    const [loadingFaculties, setLoadingFaculties] = useState(false);
    const [loadingUpdateUsers, setLoadingUpdateUsers] = useState(false);
    const [loadingImportUndergraduateStudents, setLoadingImportUndergraduateStudents] = useState(false);
    const [loadingImportMastersStudents, setLoadingImportMastersStudents] = useState(false);
    const [loadingDeleteOptionals, setLoadingDeleteOptionals] = useState(false);
    const [loadingDeleteCurriculum, setLoadingDeleteCurriculum] = useState(false);
    const [loadingImportUndergraduateCurriculum, setLoadingImportUndergraduateCurriculum] = useState(false);
    const [loadingImportMastersCurriculum, setLoadingImportMastersCurriculum] = useState(false);
    const [loadingUpdateChangeRequests, setLoadingUpdateChangeRequests] = useState(false);
    const [activeTab2Key, setActiveTab2Key] = useState("1");
    const [activeTab3Key, setActiveTab3Key] = useState("1");
    const [activeTab4Key, setActiveTab4Key] = useState("1");
    const [form] = Form.useForm();
    const {Option} = Select;

    const isEditing = (record: ConfigureOptionalResponseDTO) => record.id === editingKey;
    const invalidateQuery = () => { queryClient.invalidateQueries('GetAllUndergraduateOptionals') };
    const semesterTextMap = {
        [SemesterEnum.FirstSemester]: 'Semestrul I - Optional I',
        [SemesterEnum.SecondSemesterFirstSubject]: 'Semestrul II - Optional I',
        [SemesterEnum.SecondSemesterSecondSubject]: 'Semestrul II - Optional II',
        [SemesterEnum.All]: 'Toate',
        [SemesterEnum.FirstSemesterSecondSubject]: 'Semestrul I - Optional II',
        [SemesterEnum.SecondSemesterThirdSubject]: 'Semestrul II - Optional III'
      };

      const specializationTextMap = {
        [SpecializationEnum.C1]: 'C1',
        [SpecializationEnum.C2]: 'C2',
        [SpecializationEnum.C3]: 'C3',
        [SpecializationEnum.C4]: 'C4',
        [SpecializationEnum.C5]: 'C5',
        [SpecializationEnum.A]: 'A',
        [SpecializationEnum.B]: 'B',
        [SpecializationEnum.A1]: 'A1',
        [SpecializationEnum.A2]: 'A2',
        [SpecializationEnum.A3]: 'A3',
        [SpecializationEnum.B1]: 'B1',
        [SpecializationEnum.B2]: 'B2',
        [SpecializationEnum.B3]: 'B3',
        [SpecializationEnum.NotApplicable]: 'Fara specializare'
    };
    
    const specializationListISL3 = [
      SpecializationEnum.A,
      SpecializationEnum.B,
      SpecializationEnum.NotApplicable
    ];

    const specializationListISL4 = [
      SpecializationEnum.A1,
      SpecializationEnum.A2,
      SpecializationEnum.A3,
      SpecializationEnum.B1,
      SpecializationEnum.B2,
      SpecializationEnum.B3,
      SpecializationEnum.NotApplicable,
    ];

    const specializationNotApplicable = [
      SpecializationEnum.NotApplicable
    ];

    const specializationListCTI = [
      SpecializationEnum.C1,
      SpecializationEnum.C2,
      SpecializationEnum.C3,
      SpecializationEnum.C4,
      SpecializationEnum.C5,
      SpecializationEnum.NotApplicable,
    ];

    
    const handleUpdateFacultiesButtonClick = async () => {
        setLoadingFaculties(true);
        try {
          await updateFaculties();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingFaculties(false);
        }
      };

    const handleUpdateUsersButtonClick = async () => {
        setLoadingUpdateUsers(true);
        try {
          await updateUsers();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingUpdateUsers(false);
        }
      };
    
      const handleImportUndergraduateStudentsButtonClick = async () => {
        setActiveTab2Key("2");
        setLoadingImportUndergraduateStudents(true);
        try {
          await fetchAndImportUndergraduateStudents();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingImportUndergraduateStudents(false);
        }
      };

      const handleImportMastersStudentsButtonClick = async () => {
        setActiveTab2Key("3");
        setLoadingImportMastersStudents(true);
        try {
          await fetchAndImportMasterStudents();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingImportMastersStudents(false);
        }
      };

      const handleDeleteOptionalsButtonClick = async () => {
        setLoadingDeleteOptionals(true);
        try {
          await deleteOldOptionals();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingDeleteOptionals(false);
        }
      };

      const handleDeleteCurriculumButtonClick = async () => {
        setActiveTab3Key("2");
        setLoadingDeleteCurriculum(true);
        try {
          await deleteOldCurriculum();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingDeleteCurriculum(false);
        }
      };

      const handleImportUndergraduateCurriculumButtonClick = async () => {
        setLoadingImportUndergraduateCurriculum(true);
        try {
          await fetchAndImportUndergraduateCurriculum();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingImportUndergraduateCurriculum(false);
        }
      };

      const handleImportMastersCurriculumButtonClick = async () => {
        setActiveTab4Key("2");
        setLoadingImportMastersCurriculum(true);
        try {
          await fetchAndImportMastersCurriculum();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
          setLoadingImportMastersCurriculum(false);
        }
      };

      const handleUpdateChangeRequestsButtonClick = async () => {
        setLoadingUpdateChangeRequests(true);
        try {
          await updateNumberOfChangeRequestsAfterRepartition();
        } catch (error) {
          openErrorNotification(error);
          console.error(error);
        } finally {
            setLoadingUpdateChangeRequests(false);
        }
      };


    const openOptionalsErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchOptionalsError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['GetAllUndergraduateOptionals'],
        () => {
            return fetchAllUndergraduateOptionals();
        },
        {
            onError: openOptionalsErrorNotification,
        }
    );

    const TabContent1 = () => (
        <div>
            <h3/>
            <Card>
            <Tabs tabPosition="left">
            <TabPane tab="Import facultati" key="1">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de import al facultatilor.</h4>
                    <Button loading={loadingFaculties} onClick={() => { handleUpdateFacultiesButtonClick() }}>
                        Importa facultati
                    </Button>
                    
                </div>
            </TabPane>
            </Tabs>
            </Card>
        </div>
      );

    const TabContent2 = () => {
      const handleTabChange = (key: SetStateAction<string>) => {
          setActiveTab2Key(key);
      };
        return <div>
            <h3/>
          <Card>
            <Tabs tabPosition="left" 
                  activeKey={activeTab2Key}
                  onChange={handleTabChange}>
            <TabPane tab="Import utilizatori" key="1">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de import al utilizatorilor. Job-ul importa atat utilizatori studenti, cat si profesori sau administratori.</h4>
                    <Button loading = {loadingUpdateUsers} onClick={() => { handleUpdateUsersButtonClick() }}>
                        Importa utilizatori
                    </Button>
                </div>
            </TabPane>
            <TabPane tab="Contracte licenta" key="2">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de import al contractelor de studii pentru studentii de licenta.</h4>
                    <Button loading = {loadingImportUndergraduateStudents} onClick={() => { handleImportUndergraduateStudentsButtonClick() }}>
                        Importa contracte de studiu licenta
                    </Button>
                </div>
            </TabPane>
            <TabPane tab="Contracte master" key="3">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de import al contractelor de studii pentru studentii de master.</h4>
                    <Button loading = {loadingImportMastersStudents} onClick={() => { handleImportMastersStudentsButtonClick() }}>
                        Importa contracte de studiu master
                    </Button>
                </div>
            </TabPane>
            </Tabs>
        </Card>
        </div>
      };
      
      const TabContent3 = () => {
        const handleTabChange = (key: SetStateAction<string>) => {
          setActiveTab3Key(key);
        };
        return <div>
          <h3/>
          <Card>
            <Tabs tabPosition="left"
                  activeKey={activeTab3Key}
                  onChange={handleTabChange}>
            <TabPane tab="Stergere optionale" key="1">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de stergere al optionalelor vechi existente in baza de date.</h4>
                    <Button loading={loadingDeleteOptionals} onClick={() => { handleDeleteOptionalsButtonClick() }}>
                        Sterge optionale
                    </Button>
                </div>
            </TabPane>
            <TabPane tab="Sterge curriculum" key="2">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de stergere al curriculumului vechi din baza de date.</h4>
                    <Button loading={loadingDeleteCurriculum} onClick={() => { handleDeleteCurriculumButtonClick() }}>
                        Sterge curriculum
                    </Button>
                </div>
            </TabPane>
            </Tabs>
        </Card>
        </div>
      };
      
      const TabContent4 = () => {
        const handleTabChange = (key: SetStateAction<string>) => {
          setActiveTab4Key(key);
        };

        return <div>
          <h3/>
          <Card>
            <Tabs tabPosition="left"
                  activeKey={activeTab4Key}
                  onChange={handleTabChange}>
            <TabPane tab="Curriculum licenta" key="1">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Porneste job-ul de import al curriculumului pentru licenta.</h4>
                    <Button loading={loadingImportUndergraduateCurriculum} onClick={() => { handleImportUndergraduateCurriculumButtonClick() }}>
                        Importa curriculum licenta
                    </Button>
                </div>
            </TabPane>
            <TabPane tab="Curriculum master" key="2">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                <h4>Porneste job-ul de import al curriculumului pentru masterat.</h4>
                    <Button loading={loadingImportMastersCurriculum} onClick={() => { handleImportMastersCurriculumButtonClick() }}>
                        Importa curriculum master
                    </Button>
                </div>
            </TabPane>
            </Tabs>
        </Card>
        </div>
      };

      const openErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Datele nu au putut fi actualizate cu succes!',
            NOTIFICATION_TYPES.ERROR
          );
      }
  
      const openForbiddenErrorNotification = (_error: any) => {
          openNotification(
              t('optionals.error'),
              'Nu ai permisiuni pentru a efectua operatia!',
              NOTIFICATION_TYPES.ERROR
          );
      }

      const edit = (record: Partial<ConfigureOptionalResponseDTO> & { id?: string }) => {
        setEditingKey(record.id!);
      };
    
      const cancel = () => {
        setEditingKey('');
      };

      const save = async (key: React.Key) => {
        try {
    
            const newData = [...data ?? []];
            const index = newData.findIndex((item) => key === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                ...item,
                //...row,
                });
                console.log("newdata " + newData[index]);
                console.log(newData[index]);
                await configureUndergraduateOptionals({configureOptionalsDTO: {id: newData[index].id, semester: newData[index].semester, specialization: newData[index].specialization}})
                .then(() => {
                    console.warn("Success");
                    
                    invalidateQuery();
                });
                setEditingKey('');
            } else {
               // newData.push(row);
                setEditingKey('');
            }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
          const errorWithResponse = errInfo as {status?: number};
          if (errorWithResponse.status) {
              const statusCode = errorWithResponse.status;
              if (statusCode === 403) {
                  openForbiddenErrorNotification(errInfo);
              } else {
                  openErrorNotification(errInfo); 
              }
          } else {
            openErrorNotification(errInfo); 
          }
        }
      };

      const handleSpecializationChange = (value: SpecializationEnum, key: React.Key, record: any) => {
        const newData = [...data ?? []];
        const index = newData.findIndex((item) => key === item.id);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
            ...item,
            //...row,
            });
            newData[index].specialization = value;
            record.specialization = value;
            
        } else {
            setEditingKey('');
        }
      };

      const handleSemesterChange = (value: SemesterEnum, key: React.Key, record: any) => {
        const newData = [...data ?? []];
        const index = newData.findIndex((item) => key === item.id);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
            ...item,
            //...row,
            });
            newData[index].semester = value;
            record.semester = value;
            
        } else {
            setEditingKey('');
        }
      };

      const getSpecializationOptions = (record : ConfigureOptionalResponseDTO) => {
        var specializationList = specializationNotApplicable;
        if (record.year === StudentYearEnum.L3 && record.domain === 'IS') {
          specializationList = specializationListISL3;
        } else if (record.year === StudentYearEnum.L4 && record.domain === 'IS') {
          specializationList = specializationListISL4;
        } else if (record.year === StudentYearEnum.L4 && record.domain === 'CTI') {
          specializationList = specializationListCTI;
        }
        return specializationList.map((specialization) => (
          <Option key={specialization} value={specialization}> 
            {specializationTextMap[specialization]}
          </Option>
        ));
      };

      const getSemesterOptions = () => {
        return Object.values(SemesterEnum).map((semester) => (
          <Option key={semester} value={semester}>
            {semesterTextMap[semester]}
          </Option>
        ));
      };

      const columns = [
        {
            title: 'Nume',
            dataIndex: 'name',
            render: (text: string, record: ConfigureOptionalResponseDTO, index: number) => {
                return record.name
            }
        },
        {
          title: 'An',
          dataIndex: 'year',
          render: (text: string, record: ConfigureOptionalResponseDTO) => {
              return record.year
          }
        },
        {
          title: 'Domeniu',
          dataIndex: 'domain',
          render: (text: string, record: ConfigureOptionalResponseDTO) => {
              return record.domain
          }
        },
        {
            title: 'Specializare',
            dataIndex: 'specialization',
            key: 'specialization',
            render: (_: any, record: ConfigureOptionalResponseDTO) => {
                const editable = isEditing(record);
                return editable ? (
                  <span>
                    <Select
                        defaultValue={record.specialization}
                        style={{ width: 200 }}
                        onChange={(value) => handleSpecializationChange(value, record.id!, record)}
                    >
                        {getSpecializationOptions(record)}
                    </Select>
                  </span>
                ) : (
                  <Typography.Text>{record.specialization ? specializationTextMap[record.specialization] : 'N/A'}</Typography.Text>
                );
              },
        },
        {
            title: 'Tip optional',
            dataIndex: 'semester',
            key: 'semester',
            render: (_: any, record: ConfigureOptionalResponseDTO) => {
                const editable = isEditing(record);
                return editable ? (
                  <span>
                    <Select
                        defaultValue={record.semester}
                        style={{ width: 200 }}
                        onChange={(value) => handleSemesterChange(value, record.id!, record)}
                    >
                        {getSemesterOptions()}
                    </Select>
                  </span>
                ) : (
                  <Typography.Text>{record.semester ? semesterTextMap[record.semester] : 'N/A'}</Typography.Text>
                );
              },
        },
        {
            width: 135,
            title: 'Actiuni',
            dataIndex: 'operation',
            render: (_: any, record: ConfigureOptionalResponseDTO) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link onClick={() => save(record.id!)} style={{ marginRight: 8 }}>
                    Salvare
                  </Typography.Link>
                  <Popconfirm title="Sigur anulezi?" onConfirm={cancel} cancelText="Renunta" okText="Da">
                    <a>Anulare</a>
                  </Popconfirm>
                </span>
              ) : (
                <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                  Editare
                </Typography.Link>
              );
            },
          },
    ];

      const TabContent5 = () => (
        <div>
          <h3>Configureaza tipul si specializarea fiecarui optional listat</h3>
          <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
                <Form form={form} component={false}>
                <Table
                    className={styles.usersTable}
                    dataSource={data ?? new Array<ConfigureOptionalResponseDTO>()}

                    pagination={false}
                    rowKey={record => record.id ?? ''}
                    loading={isLoading}
                    columns={columns}
                    sticky={true}
                />
                </Form>
          </div>
        </div>
      );

      const TabContent6 = () => (
        <div>
          <h3/>
          <Card>
            <Tabs tabPosition="left">
            <TabPane tab="Cereri schimbari discipline" key="1">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100"}}>
                    <h4>Dupa finalizarea repartizarilor, porneste job-ul de salvare al numarului de cereri de schimbari de discipline pentru fiecare student.</h4>
                    <Button loading={loadingUpdateChangeRequests} onClick={() => { handleUpdateChangeRequestsButtonClick() }}>
                        Salveaza numar cereri schimbari
                    </Button>
                </div>
            </TabPane>
            </Tabs>
        </Card>
        </div>
      );

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Card>
            <Tabs defaultActiveKey="1">
            <TabPane tab="Pasul 1" key="1">
                <TabContent1 />
            </TabPane>
            <TabPane tab="Pasul 2" key="2">
                <TabContent2 />
            </TabPane>
            <TabPane tab="Pasul 3" key="3">
                <TabContent3 />
            </TabPane>
            <TabPane tab="Pasul 4" key="4">
                <TabContent4 />
            </TabPane>
            <TabPane tab="Pasul 5" key="5">
                <TabContent5 />
            </TabPane>
            <TabPane tab="Pasul 6" key="6">
                <TabContent6 />
            </TabPane>
            </Tabs>
        </Card>
        </Layout>
    </div>
}

export default AdminDataImportView;