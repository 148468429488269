/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionalsStatisticsReportDTO
 */
export interface OptionalsStatisticsReportDTO {
    /**
     * 
     * @type {string}
     * @memberof OptionalsStatisticsReportDTO
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OptionalsStatisticsReportDTO
     */
    numberOfSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof OptionalsStatisticsReportDTO
     */
    remainingSpots?: number;
    /**
     * 
     * @type {number}
     * @memberof OptionalsStatisticsReportDTO
     */
    firstChoiceNumber?: number;
}

export function OptionalsStatisticsReportDTOFromJSON(json: any): OptionalsStatisticsReportDTO {
    return OptionalsStatisticsReportDTOFromJSONTyped(json, false);
}

export function OptionalsStatisticsReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalsStatisticsReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numberOfSpots': !exists(json, 'numberOfSpots') ? undefined : json['numberOfSpots'],
        'remainingSpots': !exists(json, 'remainingSpots') ? undefined : json['remainingSpots'],
        'firstChoiceNumber': !exists(json, 'firstChoiceNumber') ? undefined : json['firstChoiceNumber'],
    };
}

export function OptionalsStatisticsReportDTOToJSON(value?: OptionalsStatisticsReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'numberOfSpots': value.numberOfSpots,
        'remainingSpots': value.remainingSpots,
        'firstChoiceNumber': value.firstChoiceNumber,
    };
}

