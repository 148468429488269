/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Faculty,
    FacultyFromJSON,
    FacultyFromJSONTyped,
    FacultyToJSON,
} from './Faculty';

/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Department
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Department
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    facultyId?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    nameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    nameEn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    externalId?: number;
    /**
     * 
     * @type {Faculty}
     * @memberof Department
     */
    faculty?: Faculty;
    /**
     * 
     * @type {Array<AcademicUser>}
     * @memberof Department
     */
    users?: Array<AcademicUser> | null;
}

export function DepartmentFromJSON(json: any): Department {
    return DepartmentFromJSONTyped(json, false);
}

export function DepartmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Department {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'facultyId': !exists(json, 'facultyId') ? undefined : json['facultyId'],
        'nameRo': !exists(json, 'nameRo') ? undefined : json['nameRo'],
        'nameEn': !exists(json, 'nameEn') ? undefined : json['nameEn'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'faculty': !exists(json, 'faculty') ? undefined : FacultyFromJSON(json['faculty']),
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(AcademicUserFromJSON)),
    };
}

export function DepartmentToJSON(value?: Department | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'facultyId': value.facultyId,
        'nameRo': value.nameRo,
        'nameEn': value.nameEn,
        'externalId': value.externalId,
        'faculty': FacultyToJSON(value.faculty),
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(AcademicUserToJSON)),
    };
}

