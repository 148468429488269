import { useState } from "react";
import styles from "./Optionals.module.scss";
import { Col, Row, Select, Spin, Button } from "antd";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import {ChangeRequestReportDTO, ChangeRequestsStatisticsReportDTO, OptionalsReportDTO, OptionalsStatisticsReportDTO, ShortSemesterEnum, SpecializationReportDTO} from "../../Api";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import CustomButton from "../../CustomComponents/CustomButton";
import { generateChangeRequestReport, generateChangeRequestStatisticsReport, generateOptionalsReport, generateOptionalsStatisticsReport, generateSpecializationReport} from "../../Requests/optionals-reports-requests";
import { utils, write } from 'xlsx';


const { Option } = Select;

const AdminReports = () => {
    const [semester, setSemester] = useState(ShortSemesterEnum.FirstSemester);
    const [isMasters, setIsMasters] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [reportType, setReportType] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        console.warn(reportType);
            
        if (reportType === 1) {
            const response = await generateChangeRequestReport(isMasters, isApproved);
            return response;
        } else if (reportType === 2) {
            const response = await generateChangeRequestStatisticsReport(isMasters);
            return response;
        } else if (reportType === 3) {
            const response = await generateOptionalsReport(isMasters);
            return response;
        } else if (reportType === 4){
            const response = await generateOptionalsStatisticsReport(isMasters, semester);
            return response;
        } else {
            const response = await generateSpecializationReport(isMasters);
            return response;
        }
    }

    const downloadExcel = (downloadData: ChangeRequestReportDTO[] 
            | ChangeRequestsStatisticsReportDTO[]
            | OptionalsReportDTO[]
            | OptionalsStatisticsReportDTO[]
            | SpecializationReportDTO[]
            | undefined) => {
        if (downloadData !== undefined) {
            let refinedData: any[] = [];
            downloadData.forEach(data => {
                if ('options' in data) {
                    const changeRequestData = data as {options?: []};
                    const flattenedOptions = changeRequestData.options?.join(', ');
                    const flattenedData = { ...data, options: flattenedOptions };
                    refinedData.push(flattenedData);
                } else if ('optionsS1' in data){
                    const changeRequestData = data as {optionsS1?: [], repartitionS1?: [], optionsS1SecondSubject?: [], repartitionS1SecondSubject?: [], 
                        optionsS2?: [], repartitionS2? :[], optionsS2SecondSubject?: [], repartitionS2SecondSubject? :[],
                        optionsS2ThirdSubject?: [], repartitionS2ThirdSubject? :[]};
                    const flattenedOptionsS1 = changeRequestData.optionsS1?.join(', ');
                    const flattenedOptionsS2 = changeRequestData.optionsS2?.join(', ');
                    const flattenedOptionsS1SecondSubject = changeRequestData.optionsS1SecondSubject?.join(', ');
                    const flattenedOptionsS2SecondSubject = changeRequestData.optionsS2SecondSubject?.join(', ');
                    const flattenedOptionsS2ThirdSubject = changeRequestData.optionsS2ThirdSubject?.join(', ');
                    const flattenedRepartitionS1 = changeRequestData.repartitionS1?.join(', ');
                    const flattenedRepartitionS2 = changeRequestData.repartitionS2?.join(', ');
                    const flattenedRepartitionS1SecondSubject = changeRequestData.repartitionS1SecondSubject?.join(', ');
                    const flattenedRepartitionS2SecondSubject = changeRequestData.repartitionS2SecondSubject?.join(', ');
                    const flattenedRepartitionS2ThirdSubject = changeRequestData.repartitionS2ThirdSubject?.join(', ');
                    const flattenedData = { 
                        ...data, 
                        optionsS1: flattenedOptionsS1, 
                        repartitionS1: flattenedRepartitionS1,
                        optionsS1SecondSubject: flattenedOptionsS1SecondSubject,
                        repartitionS1SecondSubject: flattenedRepartitionS1SecondSubject,
                        optionsS2: flattenedOptionsS2,
                        repartitionS2: flattenedRepartitionS2,
                        optionsS2SecondSubject: flattenedOptionsS2SecondSubject,
                        repartitionS2SecondSubject: flattenedRepartitionS2SecondSubject,
                        optionsS2ThirdSubject: flattenedOptionsS2ThirdSubject,
                        repartitionS2ThirdSubject: flattenedRepartitionS2ThirdSubject,
                    };
                    refinedData.push(flattenedData);
                } else if ('optionsSpecialization' in data) {
                    const changeRequestData = data as {optionsSpecialization?: [], repartitionSpecialization?: []};
                    const flattenedOptionsSpecialization = changeRequestData.optionsSpecialization?.join(', ');
                    const flattenedRepartitionSpecialization = changeRequestData.repartitionSpecialization?.join(', ');
                    const flattenedData = { 
                        ...data, 
                        optionsSpecialization: flattenedOptionsSpecialization, 
                        repartitionSpecialization: flattenedRepartitionSpecialization,
                    };
                    refinedData.push(flattenedData);
                } else {
                    refinedData.push(data);
                }
            });
            const worksheet = utils.json_to_sheet(refinedData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
            const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
            const link = document.createElement('a');
            link.href = URL.createObjectURL(dataBlob);
            link.download = 'raport-optionale.xlsx';
            link.click();
        }
      };

    const fetchDataAndDownloadExcel = async () => {
        setIsLoading(true);
        await fetchData()
            .then((response) => {
                downloadExcel(response);
                setIsLoading(false);
            });
      };

    const handleChangeMastersType = (value: boolean) => {
        //console.log(`selected ${value}`);
        setIsMasters(value);
    };

    const handleChangeChangeRequestsType = (value: boolean) => {
        //console.log(`selected ${value}`);
        setIsApproved(value);
    };

    const handleChangeType = (value: number) => {
        //console.log(`selected ${value}`);
        setReportType(value);
    };

    const handleChangeSemester = (value: ShortSemesterEnum) => {
        //console.log(`selected ${value}`);
        setSemester(value);
    };

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Row justify="center" align="middle">
                <Col span={5}>
                    <Row>
                        <Select defaultValue={false} style={{ width: 300 }} onChange={handleChangeMastersType}>
                            <Option value={false}>Licenta</Option>
                            <Option value={true}>Master</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Select placeholder="Alegere tip raport" style={{ width: 300 }} onChange={handleChangeType}>
                            <Option value={1}>Cereri de schimbare</Option>
                            <Option value={2}>Statistici cereri de schimbare</Option>
                            <Option value={3}>Optionale</Option>
                            <Option value={4}>Statistici optionale</Option>
                            <Option value={5}>Specializari</Option>
                        </Select>
                    </Row>
                    
                    <Row style={{ marginTop: 10 }}>
                        <Select placeholder="Semestru" style={{ width: 300 }} onChange={handleChangeSemester}>
                            <Option value={ShortSemesterEnum.FirstSemester}>Semestrul 1</Option>
                            <Option value={ShortSemesterEnum.SecondSemester}>Semestrul 2</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Select defaultValue={false} style={{ width: 300 }} onChange={handleChangeChangeRequestsType}>
                            <Option value={false}>Toate cererile</Option>
                            <Option value={true}>Doar cereri aprobate</Option>
                        </Select>
                    </Row>
                    <Row style={{ marginTop: 10}} justify="center">
                        <Button onClick={fetchDataAndDownloadExcel} style={{ marginRight: '10%'}}>
                            Descarca raport
                        </Button>
                        {isLoading && <Spin style={{marginTop: '15px', marginLeft: '10px'}} />}
                    </Row>
                </Col>

            </Row>
        </Layout>
    </div>
}

export default AdminReports;

function isChangeRequestReportDTO(item: any) {
    throw new Error("Function not implemented.");
}
