import { SubjectApi, ApiSubjectPostPutRequest, ApiSubjectUpdateSpotsMasterPutRequest, ImportOptionalsApi, ApiSubjectConfigureUndergraduateOptionalsPutRequest } from "../Api/apis";
import { SemesterEnum, SpecializationEnum, StudentYearEnum } from "../Api/models";
import { getAuthConfiguration } from "./configuration";

const AuthSubjectApiFactory = () => new SubjectApi(getAuthConfiguration());

const ImportOptionalsApiFactory = () => new ImportOptionalsApi(getAuthConfiguration());

export const GetAllSubjects = (semester?: SemesterEnum, year?: StudentYearEnum) => {
    return AuthSubjectApiFactory().apiSubjectGetAllGet({semester, year});
}

export const GetAllSubjectsFiltered = (name: string = '',
                                       domain: string = '',
                                       semester: SemesterEnum = SemesterEnum.All,
                                       year: StudentYearEnum = StudentYearEnum.NotApplicable,
                                       ) => {
    return AuthSubjectApiFactory().apiSubjectGetAllGet({semester, year, domain, name});
}

export const GetAllMasterSubjectsFiltered = (name: string = '',
                                       domain: string = '',
                                       semester: SemesterEnum = SemesterEnum.All,
                                       year: StudentYearEnum = StudentYearEnum.NotApplicable,
                                       program: string = '',
                                       ) => {
    return AuthSubjectApiFactory().apiSubjectGetAllMasterGet({semester, year, domain, name, program});
}

export const GetSubjectChoices = (semester?: SemesterEnum) => {
    return AuthSubjectApiFactory().apiSubjectGetChoicesBySemesterGet({semester});
}

export const ChooseSubject = (semester: SemesterEnum, choices: string[]) => {
    return AuthSubjectApiFactory().apiSubjectPostPost({ semester, userChoicesDTO: { choices }});
}

export const AdminPostSubject = (data: ApiSubjectPostPutRequest) => {
    return AuthSubjectApiFactory().apiSubjectPostPut(data);
}

export const UpdateSpotsMaster = (data: ApiSubjectUpdateSpotsMasterPutRequest) => {
    return AuthSubjectApiFactory().apiSubjectUpdateSpotsMasterPut(data);
}

export const fetchAndImportMastersCurriculum = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsFetchAndImportMastersCurriculumPost();
}

export const fetchAndImportUndergraduateCurriculum = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsFetchAndImportUndergraduateCurriculumPost();
}

export const deleteOldOptionals = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsDeleteOldOptionalsPost();
}

export const deleteOldCurriculum = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsDeleteOldCurriculumPost();
}

export const updateFaculties = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsUpdateFacultiesPost();
}

export const updateNumberOfChangeRequestsAfterRepartition = () => {
    return ImportOptionalsApiFactory().apiImportOptionalsUpdateNumberOfChangeRequestsAfterRepartitionPost();
}

export const configureUndergraduateOptionals = (data: ApiSubjectConfigureUndergraduateOptionalsPutRequest) => {
    return AuthSubjectApiFactory().apiSubjectConfigureUndergraduateOptionalsPut(data);
}

export const fetchAllUndergraduateOptionals = () => {
    return AuthSubjectApiFactory().apiSubjectFetchAllUndergraduateOptionalsGet();
}