import { FeatureFlagsApi } from "../Api/apis";
import { FeatureFlagsEnum } from "../Api/models";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new FeatureFlagsApi(getAuthConfiguration());

export const getFeatureFlagsValues = () => {
    return ApiFactory().apiFeatureFlagsGetFeatureFlagsValuesGet();
}

export const getOptionalsChoosingEnabledFlagValue = () => {
    return ApiFactory().apiFeatureFlagsGetOptionalsChoosingEnabledFlagValueGet();
}

export const getAddChangeRequestsEnabledFlagValue = () => {
    return ApiFactory().apiFeatureFlagsGetAddChangeRequestsEnabledFlagValueGet();
}

export const getViewResultsChangeRequestsEnabledFlagValue = () => {
    return ApiFactory().apiFeatureFlagsGetViewResultsChangeRequestsEnabledFlagValueGet();
}

export const getViewResultsOptionalsEnabledFlagValue = () => {
    return ApiFactory().apiFeatureFlagsGetViewResultsOptionalsEnabledFlagValueGet();
}

export const updateFeatureFlagValue = (flag: FeatureFlagsEnum, newValue: boolean ) => {
    return ApiFactory().apiFeatureFlagsUpdateFeatureFlagValuePut({updateFeatureFlagRequestDTO: {flag, newValue}})
}