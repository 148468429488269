import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Layout from "../../Containers/Layout";
import CustomCard from "../../CustomComponents/CustomCard";
import { GetChangeRequestResponse, GetSpecialChangeRequestResponse } from "../../Requests/change-subject-requests";
import { theme } from "../../theme";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import styles from "./Optionals.module.scss";
import { ChangeRequestResponseDTO } from "../../Api";
import { log } from "console";
import { useIsMasterStudent } from "../../utils/utilFunctions";


const RequestChangesResult = () => {
    const { t } = useTranslation();
    const [requests, setRequests] = useState({} as ChangeRequestResponseDTO);
    const [specialRequests, setSpecialRequests] = useState([{}] as any);
    const [available, setIsAvailable] = useState(true);
    const isMasterStudent = useIsMasterStudent();


    const openRepartitionErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.changeRequestsResponseErrorFetched'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data } = useQuery(['getChangeRequestResponse'],
        () => {
            return GetChangeRequestResponse();
        }, {
        onError: (error) => {
            const errorWithResponse = error as {status?: number};
            if (errorWithResponse.status) {
                const statusCode = errorWithResponse.status;
                if (statusCode === 403) {
                    setIsAvailable(false);
                } else {
                    openRepartitionErrorNotification(error);
                }
            } else {
                openRepartitionErrorNotification(error);
            }
        },
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (response) => {
            if (response) {
                setIsAvailable(true);
                setRequests(response);
            }
        }
    });


    const { data: specialChangeRequestResponse } = useQuery(['getSpecialChangeRequestResponse'],
        () => {
            return GetSpecialChangeRequestResponse();
        }, {
        onError: openRepartitionErrorNotification,
        refetchOnWindowFocus: false,
        refetchInterval: Infinity,
        onSuccess: (response) => {
            if (response) {
                setSpecialRequests(response)
            }
        }
    });

    return available ? (
        <Layout>
            <h2>Rezultatele cererilor de schimbare:</h2>
            {!(requests?.requests && requests?.requests?.length > 0) 
            && !(specialRequests?.length > 0) && <h3>Nu există cereri aprobate</h3>}
            {requests?.requests && requests?.requests?.length > 0 &&
                <>
                    <h2>Cereri obișnuite:</h2>
                    {requests.requests.map((request: any, index: any) =>
                        <Row gutter={[8, 16]} key={index} style={{ marginBottom: '2em', justifyContent: 'center', background: theme.lightBlue, padding: "2em", borderRadius: "0.5em" }}>
                            <Col span={window.innerWidth > 535 ? 8 : 24}>
                                <h4>Materia sursă:</h4>
                                <CustomCard
                                    backgroundcolor={theme.white}
                                    className={styles.customCard}
                                    style={{ height: "auto" }}
                                >
                                    <Row gutter={[5, 0]} style={{ justifyContent: 'center' }}>
                                        <div style={{ float: "left" }}>{request.sourceSubjectPlan?.name}</div>
                                    </Row>
                                </CustomCard>
                            </Col>
                            <Col span={window.innerWidth > 535 ? 8 : 24}>
                                <h4>Materia destinație:</h4>
                                {!isMasterStudent && request.rejectedDestinationSubjectPlans?.map((request: any, index: any) =>
                                <div style={{marginBottom: 10}}>
                                    <CustomCard
                                        backgroundcolor={theme.white}
                                        className={styles.customCard}
                                        style={{ height: 'auto'}}
                                    >
                                        <Row gutter={[5, 0]} style={{ justifyContent: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                {request.name} {request.series} - Respins
                                            </div>
                                        </Row>
                                    </CustomCard>
                                </div>
                                )}
                                {request.acceptedDestinationSubjectPlan &&
                                    <CustomCard
                                        backgroundcolor={theme.lightGreen2}
                                        className={styles.customCard}
                                        style={{ height: "auto" }}
                                    >
                                        <Row gutter={[5, 0]} style={{ justifyContent: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                <br></br>
                                                {request.acceptedDestinationSubjectPlan?.name} {request.acceptedDestinationSubjectPlan?.series} - Acceptat
                                                <br></br>
                                                <br></br>
                                            </div>
                                        </Row>
                                    </CustomCard>
                                }
                                {isMasterStudent && request.rejectedDestinationSubjectPlans?.map((request: any, index: any) =>
                                <div style={{marginTop: 10}}>
                                    <CustomCard
                                        backgroundcolor={theme.white}
                                        className={styles.customCard}
                                        style={{ height: 'auto'}}
                                    >
                                        <Row gutter={[5, 0]} style={{ justifyContent: 'center' }}>
                                            <div style={{ float: "left" }}>
                                                {request.name} {request.series} - Respins
                                            </div>
                                        </Row>
                                    </CustomCard>
                                </div>
                                )}
                            </Col>
                            {window.innerWidth > 535 && request.acceptedDestinationSubjectPlan && !isMasterStudent && <Col span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                <FontAwesomeIcon icon={regular('circle-check')} style={{ color: theme.primaryColor, width: "1.5em", height: "1.5em", padding: "2.5em" }} />
                            </Col>}
                        </Row>)}
                </>}

            {specialRequests?.length > 0 &&
                <>
                    <h2>Cereri speciale:</h2>
                    {specialRequests.map((request: any, index: any) =>
                        <Row gutter={[8, 16]} key={index} style={{ marginBottom: '2em', justifyContent: 'center', background: theme.lightBlue, padding: "2em", borderRadius: "0.5em" }}>
                            <Col span={window.innerWidth > 535 ? 8 : 24}>
                                <h4>Materia sursă:</h4>
                                <CustomCard
                                    backgroundcolor={theme.white}
                                    className={styles.customCard}
                                    style={{ height: "auto" }}
                                >
                                    <Row gutter={[5, 0]} style={{ justifyContent: 'center' }}>
                                        <div style={{ float: "left" }}>{request.sourceSubjectPlan?.name}</div>
                                    </Row>
                                </CustomCard>
                            </Col>
                            <Col span={window.innerWidth > 535 ? 5 : 16} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                <h3> a fost schimbată cu </h3>
                            </Col>
                            <Col span={window.innerWidth > 535 ? 8 : 24}>
                                <h4>Materia destinație:</h4>
                                <CustomCard
                                    backgroundcolor={theme.white}
                                    className={styles.customCard}
                                    style={{ height: "auto" }}
                                >
                                    <Row gutter={[5, 0]} style={{ justifyContent: 'center' }}>
                                        <div style={{ float: "left" }}>{request.message}</div>
                                    </Row>
                                </CustomCard>
                            </Col>
                            {window.innerWidth > 535 && <Col span={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                                <FontAwesomeIcon icon={regular('circle-check')} style={{ color: theme.primaryColor, width: "1.5em", height: "1.5em", padding: "1em" }} />
                            </Col>}
                        </Row>)}
                </>}
        </Layout>
    ) : ( 
        <Layout>
            <h2>{t('optionals.changeRequestsResponseNotEnabled')}</h2>
        </Layout>
    )
}

export default RequestChangesResult;