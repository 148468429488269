import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from '../Components/Home/Home';
import Login from '../Components/Login/Login';
import { useIsProfessor } from "../utils/utilFunctions";
import ChooseOptionals from '../Components/Optionals/ChooseOptionals';
import ChooseSpecialization from '../Components/Optionals/ChooseSpecialization';
import { isUserLogged, useIsStudent} from '../utils/utilFunctions';
import AdminSpecialization from '../Components/Optionals/AdminSpecialization';
import AdminOptionals from '../Components/Optionals/AdminOptionals';
import RepartitionResult from '../Components/Optionals/RepartitionResult';
import AdminRepartitionView from '../Components/Optionals/AdminRepartitionView';
import ChangeSubject from "../Components/Optionals/ChangeSubject";
import RequestChangesResult from '../Components/Optionals/RequestChangesResult';
import AdminChangeRequestSubjects from '../Components/Optionals/AdminChangeRequestSubjects';
import { useQuery, useQueryClient } from 'react-query';
import AdminMaster from '../Components/Optionals/AdminMaster';
import AdminReports from '../Components/Optionals/AdminReports';
import AdminFeatureFlagsView from '../Components/Optionals/AdminFeatureFlagsView';
import AdminDataImportView from '../Components/Optionals/AdminDataImportView';
import { 
  getViewResultsOptionalsEnabledFlagValue, 
  getViewResultsChangeRequestsEnabledFlagValue,
  getAddChangeRequestsEnabledFlagValue,
  getOptionalsChoosingEnabledFlagValue
} from "../Requests/feature-flags-requests";


const Router = () => {
	const isStudent = useIsStudent();
	const isProfessor = useIsProfessor();
  
  const { data: optionalsChoosingEnabled } = useQuery(['GetOptionalsChoosingEnabled'],
        () => {
            return getOptionalsChoosingEnabledFlagValue();
        }
    );

    const { data: changeRequestsEnabled} = useQuery(['GetAddChangeRequestsEnabled'],
        () => {
            return getAddChangeRequestsEnabledFlagValue();
        }
    );

    const { data: viewChangeRequestsResultsEnabled} = useQuery(['GetViewChangeRequestsResultsEnabled'],
        () => {
            return getViewResultsChangeRequestsEnabledFlagValue();
        }
    );

    const { data: viewOptionalsResultsEnabled} = useQuery(['GetViewOptionalsResultsEnabled'],
        () => {
            return getViewResultsOptionalsEnabledFlagValue();
        }
    );

  return <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/autentificare' component={Login} />
        { isUserLogged() && isStudent && optionalsChoosingEnabled?.value &&
          <Route path='/alegere-optionale' component={ChooseOptionals} />
        }
        { isUserLogged() && isStudent && optionalsChoosingEnabled?.value &&
          <Route path='/alegere-specializare' component={ChooseSpecialization} />
        }
        { isUserLogged() && isStudent && viewOptionalsResultsEnabled?.value &&
            <Route path='/rezultate-repartizare' component={RepartitionResult} />
        }
        { isUserLogged() && isStudent 
          && viewChangeRequestsResultsEnabled?.value 
          &&
            <Route path='/rezultate-cereri' component={RequestChangesResult} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/configurare-specializari' component={AdminSpecialization} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/configurare-optionale' component={AdminOptionals} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/configurare-cereri-schimbare' component={AdminChangeRequestSubjects} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/configurare-master' component={AdminMaster} />
        }
        { isUserLogged() && isStudent && changeRequestsEnabled?.value &&
            <Route path='/schimbare-materii' component={ChangeSubject} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/repartizare' component={AdminRepartitionView} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/rapoarte' component={AdminReports} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/flags' component={AdminFeatureFlagsView} />
        }
        { isUserLogged() && isProfessor &&
          <Route path='/import-date' component={AdminDataImportView} />
        }

        <Redirect from='*' to='/' />
      </Switch>
    </BrowserRouter>
};

export default Router;