/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionalsReportsDTO
 */
export interface OptionalsReportsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OptionalsReportsDTO
     */
    masters?: boolean;
}

export function OptionalsReportsDTOFromJSON(json: any): OptionalsReportsDTO {
    return OptionalsReportsDTOFromJSONTyped(json, false);
}

export function OptionalsReportsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalsReportsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'masters': !exists(json, 'masters') ? undefined : json['masters'],
    };
}

export function OptionalsReportsDTOToJSON(value?: OptionalsReportsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'masters': value.masters,
    };
}

