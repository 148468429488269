/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SemesterEnum,
    SemesterEnumFromJSON,
    SemesterEnumFromJSONTyped,
    SemesterEnumToJSON,
} from './SemesterEnum';
import {
    SpecializationEnum,
    SpecializationEnumFromJSON,
    SpecializationEnumFromJSONTyped,
    SpecializationEnumToJSON,
} from './SpecializationEnum';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface ConfigureOptionalResponseDTO
 */
export interface ConfigureOptionalResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ConfigureOptionalResponseDTO
     */
    id?: string;
    /**
     * 
     * @type {SpecializationEnum}
     * @memberof ConfigureOptionalResponseDTO
     */
    specialization?: SpecializationEnum;
    /**
     * 
     * @type {SemesterEnum}
     * @memberof ConfigureOptionalResponseDTO
     */
    semester?: SemesterEnum;
    /**
     * 
     * @type {string}
     * @memberof ConfigureOptionalResponseDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigureOptionalResponseDTO
     */
    domain?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof ConfigureOptionalResponseDTO
     */
    year?: StudentYearEnum;
}

export function ConfigureOptionalResponseDTOFromJSON(json: any): ConfigureOptionalResponseDTO {
    return ConfigureOptionalResponseDTOFromJSONTyped(json, false);
}

export function ConfigureOptionalResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigureOptionalResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'specialization': !exists(json, 'specialization') ? undefined : SpecializationEnumFromJSON(json['specialization']),
        'semester': !exists(json, 'semester') ? undefined : SemesterEnumFromJSON(json['semester']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
    };
}

export function ConfigureOptionalResponseDTOToJSON(value?: ConfigureOptionalResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'specialization': SpecializationEnumToJSON(value.specialization),
        'semester': SemesterEnumToJSON(value.semester),
        'name': value.name,
        'domain': value.domain,
        'year': StudentYearEnumToJSON(value.year),
    };
}

