/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Specialization,
    SpecializationFromJSON,
    SpecializationFromJSONTyped,
    SpecializationToJSON,
} from './Specialization';

/**
 * 
 * @export
 * @interface SpecializationRepartitionDTO
 */
export interface SpecializationRepartitionDTO {
    /**
     * 
     * @type {Array<Specialization>}
     * @memberof SpecializationRepartitionDTO
     */
    acceptedSpecializations?: Array<Specialization> | null;
    /**
     * 
     * @type {Array<Specialization>}
     * @memberof SpecializationRepartitionDTO
     */
    rejectedSpecializations?: Array<Specialization> | null;
}

export function SpecializationRepartitionDTOFromJSON(json: any): SpecializationRepartitionDTO {
    return SpecializationRepartitionDTOFromJSONTyped(json, false);
}

export function SpecializationRepartitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecializationRepartitionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptedSpecializations': !exists(json, 'acceptedSpecializations') ? undefined : (json['acceptedSpecializations'] === null ? null : (json['acceptedSpecializations'] as Array<any>).map(SpecializationFromJSON)),
        'rejectedSpecializations': !exists(json, 'rejectedSpecializations') ? undefined : (json['rejectedSpecializations'] === null ? null : (json['rejectedSpecializations'] as Array<any>).map(SpecializationFromJSON)),
    };
}

export function SpecializationRepartitionDTOToJSON(value?: SpecializationRepartitionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptedSpecializations': value.acceptedSpecializations === undefined ? undefined : (value.acceptedSpecializations === null ? null : (value.acceptedSpecializations as Array<any>).map(SpecializationToJSON)),
        'rejectedSpecializations': value.rejectedSpecializations === undefined ? undefined : (value.rejectedSpecializations === null ? null : (value.rejectedSpecializations as Array<any>).map(SpecializationToJSON)),
    };
}

