/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface SemesterRepartitionDTO
 */
export interface SemesterRepartitionDTO {
    /**
     * 
     * @type {Array<Subject>}
     * @memberof SemesterRepartitionDTO
     */
    acceptedOptionals?: Array<Subject> | null;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof SemesterRepartitionDTO
     */
    rejectedOptionals?: Array<Subject> | null;
}

export function SemesterRepartitionDTOFromJSON(json: any): SemesterRepartitionDTO {
    return SemesterRepartitionDTOFromJSONTyped(json, false);
}

export function SemesterRepartitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SemesterRepartitionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptedOptionals': !exists(json, 'acceptedOptionals') ? undefined : (json['acceptedOptionals'] === null ? null : (json['acceptedOptionals'] as Array<any>).map(SubjectFromJSON)),
        'rejectedOptionals': !exists(json, 'rejectedOptionals') ? undefined : (json['rejectedOptionals'] === null ? null : (json['rejectedOptionals'] as Array<any>).map(SubjectFromJSON)),
    };
}

export function SemesterRepartitionDTOToJSON(value?: SemesterRepartitionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptedOptionals': value.acceptedOptionals === undefined ? undefined : (value.acceptedOptionals === null ? null : (value.acceptedOptionals as Array<any>).map(SubjectToJSON)),
        'rejectedOptionals': value.rejectedOptionals === undefined ? undefined : (value.rejectedOptionals === null ? null : (value.rejectedOptionals as Array<any>).map(SubjectToJSON)),
    };
}

