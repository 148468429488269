/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShortSemesterEnum,
    ShortSemesterEnumFromJSON,
    ShortSemesterEnumFromJSONTyped,
    ShortSemesterEnumToJSON,
} from './ShortSemesterEnum';

/**
 * 
 * @export
 * @interface OptionalsStatisticsReportRequestDTO
 */
export interface OptionalsStatisticsReportRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OptionalsStatisticsReportRequestDTO
     */
    masters?: boolean;
    /**
     * 
     * @type {ShortSemesterEnum}
     * @memberof OptionalsStatisticsReportRequestDTO
     */
    semester?: ShortSemesterEnum;
}

export function OptionalsStatisticsReportRequestDTOFromJSON(json: any): OptionalsStatisticsReportRequestDTO {
    return OptionalsStatisticsReportRequestDTOFromJSONTyped(json, false);
}

export function OptionalsStatisticsReportRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalsStatisticsReportRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'masters': !exists(json, 'masters') ? undefined : json['masters'],
        'semester': !exists(json, 'semester') ? undefined : ShortSemesterEnumFromJSON(json['semester']),
    };
}

export function OptionalsStatisticsReportRequestDTOToJSON(value?: OptionalsStatisticsReportRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'masters': value.masters,
        'semester': ShortSemesterEnumToJSON(value.semester),
    };
}

