/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeRequestDTO,
    ChangeRequestDTOFromJSON,
    ChangeRequestDTOFromJSONTyped,
    ChangeRequestDTOToJSON,
} from './ChangeRequestDTO';
import {
    SpecialChangeRequestDTO,
    SpecialChangeRequestDTOFromJSON,
    SpecialChangeRequestDTOFromJSONTyped,
    SpecialChangeRequestDTOToJSON,
} from './SpecialChangeRequestDTO';

/**
 * 
 * @export
 * @interface ChangeRequestsDTO
 */
export interface ChangeRequestsDTO {
    /**
     * 
     * @type {Array<ChangeRequestDTO>}
     * @memberof ChangeRequestsDTO
     */
    changeRequests?: Array<ChangeRequestDTO> | null;
    /**
     * 
     * @type {Array<SpecialChangeRequestDTO>}
     * @memberof ChangeRequestsDTO
     */
    specialChangeRequests?: Array<SpecialChangeRequestDTO> | null;
}

export function ChangeRequestsDTOFromJSON(json: any): ChangeRequestsDTO {
    return ChangeRequestsDTOFromJSONTyped(json, false);
}

export function ChangeRequestsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'changeRequests': !exists(json, 'changeRequests') ? undefined : (json['changeRequests'] === null ? null : (json['changeRequests'] as Array<any>).map(ChangeRequestDTOFromJSON)),
        'specialChangeRequests': !exists(json, 'specialChangeRequests') ? undefined : (json['specialChangeRequests'] === null ? null : (json['specialChangeRequests'] as Array<any>).map(SpecialChangeRequestDTOFromJSON)),
    };
}

export function ChangeRequestsDTOToJSON(value?: ChangeRequestsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'changeRequests': value.changeRequests === undefined ? undefined : (value.changeRequests === null ? null : (value.changeRequests as Array<any>).map(ChangeRequestDTOToJSON)),
        'specialChangeRequests': value.specialChangeRequests === undefined ? undefined : (value.specialChangeRequests === null ? null : (value.specialChangeRequests as Array<any>).map(SpecialChangeRequestDTOToJSON)),
    };
}

