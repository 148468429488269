import { useState } from "react";
import styles from "./Optionals.module.scss";
import { Form, Input, InputNumber, Popconfirm, Select, Table, Typography } from "antd";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { StudentYearEnum, SubjectPlan } from "../../Api";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../../Containers/Layout";
import React from "react";
import { useDebounce } from "usehooks-ts";
import { AdminUpdateAvailablePositions, GetAllChangeRequestSubjectsFiltered } from "../../Requests/change-subject-requests";


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: SubjectPlan;
    index: number;
    children: React.ReactNode;
  }
  
  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === 'number' ? <InputNumber onFocus={(event) => {event.target.select();}} /> : <Input onFocus={(event) => {event.target.select();}} />;
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };


const AdminChangeRequestSubjects = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTermDomain, setSearchTermDomain] = useState('');
    const [searchTermYear, setSearchTermYear] = useState(StudentYearEnum.NotApplicable);
    const { Option } = Select;

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record: SubjectPlan) => record.id === editingKey;
    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('GetAllChangeRequestSubjectsFiltered') };

    const openSpecializationErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            t('optionals.fetchSpecializationsError'),
            NOTIFICATION_TYPES.ERROR
        );
    }

    const openErrorNotification = (_error: any) => {
      openNotification(
          t('optionals.error'),
          'Datele nu au putut fi actualizate cu succes!',
          NOTIFICATION_TYPES.ERROR
        );
    }

    const openForbiddenErrorNotification = (_error: any) => {
        openNotification(
            t('optionals.error'),
            'Nu ai permisiuni pentru a efectua operatia!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const { data, isLoading } = useQuery(['GetAllChangeRequestSubjectsFiltered', searchTermName, searchTermDomain, searchTermYear],
        () => {
            return GetAllChangeRequestSubjectsFiltered(searchTermName, searchTermDomain, searchTermYear);
        },
        {
            onError: openSpecializationErrorNotification,
        }
    );


    const edit = (record: Partial<SubjectPlan> & { id?: string }) => {
        form.setFieldsValue({ minimumSpots: '', maximumSpots: '', enrolledStudents: '', ...record });
        setEditingKey(record.id!);
      };
    
      const cancel = () => {
        setEditingKey('');
      };
    
      const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as SubjectPlan;
    
            const newData = [...data ?? []];
            const index = newData.findIndex((item) => key === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                ...item,
                ...row,
                });
                console.log(newData[index]);
                await AdminUpdateAvailablePositions({updateNumberOfSpotsDTO: {availableSpots: newData[index].initialAvailableSpots,
                                                                        enrolledStudents: newData[index].initialEnrolledStudents, 
                                                                        id: newData[index].id}})
                .then(() => {
                    console.warn("Success");
                    
                    invalidateQuery();
                });
                setEditingKey('');
            } else {
                newData.push(row);
                setEditingKey('');
            }
        } catch (errInfo) {
          console.log('Validate Failed:', errInfo);
          const errorWithResponse = errInfo as {status?: number};
          if (errorWithResponse.status) {
              const statusCode = errorWithResponse.status;
              if (statusCode === 403) {
                  openForbiddenErrorNotification(errInfo);
              } else {
                  openErrorNotification(errInfo); 
              }
          } else {
            openErrorNotification(errInfo); 
          }
        }
      };


    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: 400 }} />
    );

    const FilterByDomainInput = (
        <Select placeholder="An" style={{ width: 100 }} onChange={setSearchTermDomain} defaultValue={''}>
            <Option value={''}>Toate</Option>
            <Option value={'CTI'}>CTI</Option>
            <Option value={'IS'}>IS</Option>
        </Select>
    );

    const FilterByYearInput = (
        <Select placeholder="An" style={{ width: 100 }} onChange={setSearchTermYear} defaultValue={StudentYearEnum.NotApplicable}>
                            <Option value={StudentYearEnum.NotApplicable}>Toate</Option>
                            <Option value={StudentYearEnum.L1}>L1</Option>
                            <Option value={StudentYearEnum.L2}>L2</Option>
                            <Option value={StudentYearEnum.L3}>L3</Option>
                            <Option value={StudentYearEnum.L4}>L4</Option>
                            <Option value={StudentYearEnum.M1}>M1</Option>
                            <Option value={StudentYearEnum.M2}>M2</Option>
                        </Select>
    );

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            render: (text: string, record: SubjectPlan, index: number) => {
                return !!record.series
                ? record.name + " (" + record.series + ")"
                : record.name
            }
        },
        {
            width: 135,
            title: FilterByDomainInput,
            dataIndex: 'domain',
            key: 'domain',
        },
        {
            width: 135,
            title: FilterByYearInput,
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Număr plecări (maxim)',
            dataIndex: 'initialEnrolledStudents',
            key: 'initialEnrolledStudents',
            editable: true,
        },
        {
            title: 'Număr veniri (maxim)',
            dataIndex: 'initialAvailableSpots',
            key: 'initialAvailableSpots',
            editable: true,
        },
        {
            width: 135,
            title: 'Actiuni',
            dataIndex: 'operation',
            render: (_: any, record: SubjectPlan) => {
              const editable = isEditing(record);
              return editable ? (
                <span>
                  <Typography.Link onClick={() => save(record.id!)} style={{ marginRight: 8 }}>
                    Salvare
                  </Typography.Link>
                  <Popconfirm title="Sigur anulezi?" onConfirm={cancel} cancelText="Renunta" okText="Da">
                    <a>Anulare</a>
                  </Popconfirm>
                </span>
              ) : (
                <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                  Editare
                </Typography.Link>
              );
            },
          },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: SubjectPlan) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
            <Form form={form} component={false}>
            <div
            style={{
                height: "calc(100vh - 180px)",
                overflow: "auto"
            }}
            >
                <Table
                    className={styles.usersTable}
                    components={{
                        body: {
                        cell: EditableCell,
                        },
                    }}
                    dataSource={data ?? new Array<SubjectPlan>()}
                    columns={mergedColumns}
                    pagination={false}
                    rowKey={record => record.id ?? ''}
                    loading={isLoading}
                    sticky={true}
                />
            </div>
            </Form>
        </Layout>
    </div>
}

export default AdminChangeRequestSubjects;