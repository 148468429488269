/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StudentYearEnum {
    NotApplicable = 'NotApplicable',
    L1 = 'L1',
    L2 = 'L2',
    L3 = 'L3',
    L4 = 'L4',
    M1 = 'M1',
    M2 = 'M2',
    D1 = 'D1',
    D2 = 'D2',
    D3 = 'D3',
    D4 = 'D4',
    D5 = 'D5'
}

export function StudentYearEnumFromJSON(json: any): StudentYearEnum {
    return StudentYearEnumFromJSONTyped(json, false);
}

export function StudentYearEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudentYearEnum {
    return json as StudentYearEnum;
}

export function StudentYearEnumToJSON(value?: StudentYearEnum | null): any {
    return value as any;
}

