import { SpecializationApi, ApiSpecializationPostPutRequest } from "../Api/apis";
import { getAuthConfiguration } from "./configuration";

const AuthSpecializationApiFactory = () => new SpecializationApi(getAuthConfiguration());

export const GetAllSpecialization = () => {
    return AuthSpecializationApiFactory().apiSpecializationGetAllGet();
}

export const GetAllSpecializationFiltered = (name: string = '', domain: string = '') => {
    return AuthSpecializationApiFactory().apiSpecializationGetAllGet({domain, name});
}

export const GetSpecializationChoices = () => {
    return AuthSpecializationApiFactory().apiSpecializationGetChoicesGet();
}

export const ChooseSpecialization = (choices: any) => {
    return AuthSpecializationApiFactory().apiSpecializationPostPost({userChoicesDTO: {choices}});
}

export const AdminPostSpecialization = (data: ApiSpecializationPostPutRequest) => {
    return AuthSpecializationApiFactory().apiSpecializationPostPut(data);
}
