/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectionCountResponseDTO
 */
export interface SelectionCountResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof SelectionCountResponseDTO
     */
    optionalsSelectionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SelectionCountResponseDTO
     */
    specializationSelectionCount?: number;
}

export function SelectionCountResponseDTOFromJSON(json: any): SelectionCountResponseDTO {
    return SelectionCountResponseDTOFromJSONTyped(json, false);
}

export function SelectionCountResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionCountResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionalsSelectionCount': !exists(json, 'optionalsSelectionCount') ? undefined : json['optionalsSelectionCount'],
        'specializationSelectionCount': !exists(json, 'specializationSelectionCount') ? undefined : json['specializationSelectionCount'],
    };
}

export function SelectionCountResponseDTOToJSON(value?: SelectionCountResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionalsSelectionCount': value.optionalsSelectionCount,
        'specializationSelectionCount': value.specializationSelectionCount,
    };
}

