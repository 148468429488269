/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface ApproveChangeRequestsSimulationDTO
 */
export interface ApproveChangeRequestsSimulationDTO {
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof ApproveChangeRequestsSimulationDTO
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof ApproveChangeRequestsSimulationDTO
     */
    domain?: string | null;
}

export function ApproveChangeRequestsSimulationDTOFromJSON(json: any): ApproveChangeRequestsSimulationDTO {
    return ApproveChangeRequestsSimulationDTOFromJSONTyped(json, false);
}

export function ApproveChangeRequestsSimulationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveChangeRequestsSimulationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
    };
}

export function ApproveChangeRequestsSimulationDTOToJSON(value?: ApproveChangeRequestsSimulationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': StudentYearEnumToJSON(value.year),
        'domain': value.domain,
    };
}

