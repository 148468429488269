import {useState, useEffect} from 'react';
import styles from './NavBar.module.scss';
import logo from '../../Media/logoheader.svg';
import logoACS from '../../Media/acs-logo-ro.png';
import { Link, useLocation, useHistory } from 'react-router-dom';
import CustomButton from '../../CustomComponents/CustomButton';
import { theme } from '../../theme';
import { isUserLogged, useIsCompany, useIsStudent} from '../../utils/utilFunctions';
import NavBarMobile from './NavBarMobile';
import { useDispatch } from "react-redux";
import { logout } from '../../utils/utilFunctions';
import { useTranslation } from "react-i18next";
import { Badge, Select, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import OptionalsHeadersLinks from "./OptionalsHeadersLinks";

const { Option } = Select;

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const isStudent = useIsStudent();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isCompany = useIsCompany();

    const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  const [state, setState] = useState({
    mounted: false,
  });

  const handleClick = () => {
    history.push("/");
  }

  useEffect(() => {
    if (!state.mounted) {
      setState((prevState) => ({
        ...prevState,
        mounted: true,
      }));
    }
  }, [state.mounted]);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.firstChildACS}>
        <img alt={'logo'} src={logoACS} onClick={handleClick} />
      </div>
      <div className = {styles.secondChildWrapper}>
        <div className={styles.secondChild}>
            <OptionalsHeadersLinks/>
        </div>
      </div>

      <div className={styles.thirdChild}>
        <a href='https://support.upb.ro/open.php' target={'_blank'} style={{marginRight: "10px"}}>
          <Tooltip title="Suport">
            <FontAwesomeIcon icon={solid('headset')} style={{fontSize: theme.headerSizeIcons }}/>
          </Tooltip>
        </a>


        {!isUserLogged() ? 
          <CustomButton
            backgroundcolor={theme.primaryColorACS}
            textcolor={theme.white}
            margintop={"0"}>
              <Link to='/autentificare'>
                {t('header.login')}
              </Link>
          </CustomButton>
        : null}

        {isUserLogged() ?
          <Link to="/"
            onClick={() => logout(!isCompany)}
            style={{ 
              margin: '0 5% 0 10%',
              padding: '5px'
            }}>
            <Tooltip title={t('header.signout')}>
              <FontAwesomeIcon icon={solid('right-from-bracket')} style={{fontSize: theme.headerSizeIcons }}/>
            </Tooltip>
          </Link>
        : null }

      </div>

      <NavBarMobile/>

    </div>
  );
};

export default NavBar;

